import React from "react";
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { formatoMoneda } from "../../../../utils/parsers";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CallMadeIcon from "@material-ui/icons/CallMade";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
import useDisclosure from "../../../../customHooks/useDisclosure";
import ModalEliminarMovimiento from "./componentes/ModalEliminarMovimiento";

const Acciones = ({ movimiento }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [
    isOpenModalEliminarMovimiento,
    { toggle: toggleOpenModalEliminarMovimiento },
  ] = useDisclosure(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const eliminarMovimiento = () => {
    toggleOpenModalEliminarMovimiento();
    handleClose();
  };

  return (
    <Box>
      <span>
        <IconButton
          size="small"
          onClick={handleClick}
          disabled={movimiento.es_sistema}
        >
          <SvgIcon>
            <MoreVertIcon />
          </SvgIcon>
        </IconButton>
      </span>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!movimiento.es_sistema && (
          <MenuItem onClick={eliminarMovimiento}>Eliminar</MenuItem>
        )}
      </Menu>
      {isOpenModalEliminarMovimiento && (
        <ModalEliminarMovimiento
          open={isOpenModalEliminarMovimiento}
          handleClose={toggleOpenModalEliminarMovimiento}
          movimiento={movimiento}
        />
      )}
    </Box>
  );
};

const Categoria = ({ movimiento }) => {
  const Icon = (
    <Avatar
      style={{
        backgroundColor: movimiento.es_entrada
          ? "rgb(0, 183, 81)"
          : "rgb(255, 121, 13)",
        color: "white",
        marginRight: "8px",
        width: "24px",
        height: "24px",
      }}
    >
      <SvgIcon>
        {movimiento.es_entrada ? <CallMadeIcon /> : <CallReceivedIcon />}
      </SvgIcon>
    </Avatar>
  );

  return (
    <Box display={"flex"} alignItems={"center"}>
      {Icon}
      <Typography variant="body1">{movimiento.categoria}</Typography>
    </Box>
  );
};

export default function formatMovimientosCuenta(movimientos) {
  return movimientos.map((movimiento) => {
    const { categoria, fecha, descripcion, importe, cuenta, responsable } =
      movimiento;
    return {
      fecha: moment(fecha).format("DD/MM/YYYY HH:mm"),
      responsable: responsable || "--",
      categoria: <Categoria movimiento={movimiento} />,
      descripcion,
      importe: formatoMoneda({ value: importe }),
      acciones: <Acciones movimiento={movimiento} />,
    };
  });
}
