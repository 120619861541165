import moment from "moment";
import { getHostClient } from "../utils";
import { getVariantsBoxerDisabled } from "../VinculacionPublicaciones/utils";
import { errorNotification } from "../../../../components/Notifications";

export const publicacionesFromApi = (data) => {
  return {
    count: data.count,
    results: data.results.map((publicacion) => ({
      // fechaInicio: moment(publicacion.start_time).format("DD/MM/YYYY"),
      id: publicacion.id,
      titulo: publicacion.title,
      subtitulo: publicacion.subtitle,
      precio: `$${parseFloat(publicacion.price).toLocaleString("es-AR")}`,
      imagen: publicacion.pictures[0].secure_url,
      cantDisponible: publicacion.available_quantity,
      vinculada: publicacion.linked,
      tipo: publicacion.listing_type_id,
      link: publicacion.permalink,
      stock: publicacion.available_quantity,
      fechaModificacion: publicacion.api
        ? moment(publicacion.api.updated_at).format("DD/MM/YYYY HH:mm")
        : null,
      articulos: publicacion.api ? publicacion.api.products : null,
      isCombo: publicacion.api ? publicacion.api.is_combo : false,
      variants: publicacion.variations,
    })),
  };
};

export const publicacionFromApi = (data) => {
  const publicacion = data;

  return {
    dataApi: publicacion.api_data,
    vinculada: publicacion.linked,
    precio: publicacion.meli_data.price,
    stock: publicacion.meli_data.initial_quanty,
    idPublicacion: publicacion.meli_data.id,
    titulo: publicacion.meli_data.title,
    imagen: publicacion.meli_data.secure_thumbnail,
    link: publicacion.meli_data.permalink,
    tipo: publicacion.meli_data.listing_type_id,
    meliData: publicacion.meli_data,
  };
};

export const publicacionToApi = (
  isEdit,
  data,
  articulos,
  idPublicacion,
  publicacion,
  dataErrorVariants,
  dataDesvinculacionVariante,
  CalculateStockCombo,
  host,
) => {
  let newProducts = [];
  let errorVariantes = false;
  articulos.forEach((art) => {
    let varianteArticulo = data.variantes.filter(
      (v) =>
        v.article.idRepuestoProveedor === art.idRepuestoProveedor &&
        art.idVariante === v.id,
    )[0];

    //Se agrega validacion para que si hay variantes, siempre haya una variante seleccionada para el articulo
    if (data.variantes.length > 0 && !varianteArticulo) {
      errorVariantes = true;
    }

    newProducts.push({
      item_id: art.idRepuestoProveedor,
      sales_unit_price: art.precios.venta,
      quantity: art.cantidad,
      idRepuestoProveedor: art.idRepuestoProveedor,
      is_base_price:
        data.variantes.length > 0 && varianteArticulo
          ? varianteArticulo.is_base_price
          : false,
      variant_id:
        data.variantes.length > 0 && varianteArticulo
          ? varianteArticulo.id
          : null,
      sync_stock:
        data.variantes.length > 0 && varianteArticulo
          ? varianteArticulo.sync_stock
          : false,
      stock:
        data.variantes.length > 0 &&
        varianteArticulo &&
        varianteArticulo.sync_stock
          ? varianteArticulo.stock >= 0
            ? varianteArticulo.stock
            : 0
          : 0,
      idListaPrecios: art.id_lista_precios
        ? art.id_lista_precios
        : art.idListaPrecio,
    });
  });

  if (errorVariantes) {
    errorNotification(
      "Ocurrío un error al relacionar las variantes con los artículos, por favor reintente.",
    );
    return null;
  } else {
    const newData = {
      meli_publication_id: idPublicacion,
      client_url: getHostClient(),
      pivot:
        data.tipo === 0
          ? Number(data.porcentaje)
          : Number(data.porcentaje) * -1,
      price: Number(data.precioTotal),
      sync_stock: data.stock,
      stock:
        !data.stock || data.isCombo
          ? host === "testing"
            ? CalculateStockCombo
            : 0
          : articulos[0].repuesto_sucursal.stockDisponible >= 0
            ? articulos[0].repuesto_sucursal.stockDisponible
            : 0,
      is_combo: data.isCombo,
      products: newProducts,
      variations:
        data.variantes.length > 0
          ? data.variantes.map((v) => {
              const price = Number(data.precioTotal); //Se agrega directamente el precio total para que todas tengan el mismo precio
              return v.sync_stock
                ? {
                    id: v.id,
                    price: price,
                    available_quantity: v.stock >= 0 ? v.stock : 0,
                  }
                : {
                    id: v.id,
                    price: price,
                  };
            })
          : null,
      variations_boxer_disabled: isEdit
        ? getVariantsBoxerDisabled(publicacion)
        : null,
    };

    if (isEdit) {
      newData.products.forEach((producto) => {
        let articulosPublicacion = [];
        if (
          publicacion.meliData &&
          publicacion.meliData.variations.length > 0
        ) {
          articulosPublicacion = publicacion.dataApi.variants.map(
            (v) => v.products[0],
          );
        } else {
          articulosPublicacion = publicacion.dataApi.products;
        }

        let pSelect = null;
        if (producto.variant_id) {
          pSelect = articulosPublicacion.filter(
            (p) =>
              p.idRepuestoProveedor === producto.idRepuestoProveedor &&
              p.id_variant === producto.variant_id,
          )[0];
        } else {
          pSelect = articulosPublicacion.filter(
            (p) => p.idRepuestoProveedor === producto.idRepuestoProveedor,
          )[0];
        }
        producto.id = pSelect ? pSelect.id : null;
        producto.is_active = true;
      });
      newData.is_active = true;
      newData.activate_publication_variant = dataErrorVariants ? true : false;
    } else {
      newData.listing_type_id = publicacion.tipo;
      newData.activate_pause_publication = dataDesvinculacionVariante
        ? dataDesvinculacionVariante.desvinculacionErrorVariante
        : false;
    }

    newData.products.forEach((producto) => {
      delete producto.idRepuestoProveedor;
    });

    return newData;
  }
};

export const publicacionesToApi = (data, articulos, publicaciones, host) => {
  let newProducts = [];

  articulos.forEach((art) => {
    newProducts.push({
      item_id: art.idRepuestoProveedor,
      sales_unit_price: art.precios.venta,
      quantity: art.cantidad,
      idRepuestoProveedor: art.idRepuestoProveedor,
      idListaPrecios: art.id_lista_precios,
    });
  });

  const newDataArray = publicaciones.map((publicacion) => {
    const newData = {
      meli_publication_id: publicacion.id,
      client_url: getHostClient(),
      pivot:
        data.tipo === 0
          ? Number(data.porcentaje)
          : Number(data.porcentaje) * -1,
      price: Number(data.precioTotal),
      sync_stock: data.stock,
      stock:
        !data.stock || data.isCombo
          ? host === "testing"
            ? 0
            : 0
          : articulos[0].repuesto_sucursal.stockDisponible >= 0
            ? articulos[0].repuesto_sucursal.stockDisponible
            : 0,
      is_combo: data.isCombo,
      products: newProducts,
      vinculada: publicacion.vinculada,
    };

    if (publicacion.isEdit) {
      newData.products.forEach((producto) => {
        let pSelect = publicacion.dataApi.products.find(
          (p) => p.idRepuestoProveedor === producto.idRepuestoProveedor,
        );
        if (pSelect) {
          producto.id = pSelect.id;
          producto.is_active = true;
        }
      });
      newData.is_active = true;
    } else {
      newData.listing_type_id = publicacion.tipo;
    }

    return newData;
  });

  return { publications: newDataArray, products: newProducts };
};
