import React, { useEffect, useState } from "react";
import {
  Dialog,
  Divider,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { ButtonModalMaterial } from "../../../components/ait-reusable/ButtonModalMaterial";
import { useSelector, useDispatch } from "react-redux";
import FormCompleteMedios from "./FormCompleteMedios";
import { updateMontoConInteres } from "../../../Redux/Actions/presupuestoActions";

export default function ModalPagosMultiples({
  open,
  handleClose,
  tiposMedios,
  idPresupuesto,
  handlePostVentaPresup,
  configCCC,
}) {
  const { medioPago, montos, isMultipleMedioPago, cliente } = useSelector(
    (store) => store.presupuesto,
  );
  const dispatch = useDispatch();

  const postNewVenta = () => {
    // dispatch(updateMontoConInteres(medioPago))
    handlePostVentaPresup();
  };

  return (
    <div>
      <Dialog fullWidth aria-labelledby="customized-dialog-title" open={open}>
        <ButtonModalMaterial id="customized-dialog-title" onClose={handleClose}>
          Pago Múltiple
        </ButtonModalMaterial>
        <Divider />
        <div style={{ margin: 10, paddingLeft: 10, paddingRight: 10 }}>
          <FormCompleteMedios
            tiposMedios={tiposMedios}
            montos={montos}
            medioPago={medioPago}
            idPresupuesto={idPresupuesto}
            postNewVenta={postNewVenta}
            isMultipleMedio={isMultipleMedioPago}
            cliente={cliente}
            configCCC={configCCC}
          />
        </div>
      </Dialog>
    </div>
  );
}
