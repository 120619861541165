import React, { useState, useEffect } from "react";
import { useDebounce } from "../../../customHooks/useDebounce";
import { Grid, TextField, Divider } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import request from "../../../requests/request";
import {
  readClientesVenta,
  listEstadoTributario,
} from "../../../requests/urls";
import { useSelector, useDispatch } from "react-redux";
import FormNuevoCliente from "./FormNuevoCliente";
import useClienteDescuentoPlazo from "../../../customHooks/useClienteDescuentoPlazo";
import { updateProvincia } from "../../../Redux/Actions/presupuestoActions";

const conFObject = {
  cliente: { razonSocial: "" },
};

export default function ContentModal({
  formRef,
  onSubmit,
  provinciaDefault,
  valuesPrevious,
  clienteInactivo,
  montoMaximo,
}) {
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [descripcion, setDescripcion] = useState("");
  const debouncedDescripcion = useDebounce(descripcion, 300);
  const [autocompleteCliente, setAutocompleteCliente] = useState(conFObject);
  const [nuevoCliente, setNuevoCliente] = useState(
    valuesPrevious
      ? valuesPrevious
      : {
          razonSocial: "",
          idEstadoTributario: "",
          numeroDocumento: "",
        },
  );
  const [estados, setEstados] = useState([]);
  const { cliente, provincia } = useSelector((state) => state.presupuesto);
  const dispatch = useDispatch();
  const { setNewClient } = useClienteDescuentoPlazo();

  useEffect(() => {
    handleGetClientes();
    getEstadosTributarios();
  }, [debouncedDescripcion]);

  useEffect(() => {
    !cliente && setAutocompleteCliente(conFObject);
    cliente && setAutocompleteCliente(cliente);
  }, [cliente]);

  const handleGetClientes = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: readClientesVenta,
        params: { q: descripcion, blank: descripcion === "" ? 1 : 0 },
      });
      handleResponseGetClientes(response);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleResponseGetClientes = (response) => {
    const { data, err_code, err_messege, status } = response.data;
    {
      err_code === 204 && status === 200 && setClientes([]);
    }
    {
      err_code === 200 &&
        status === 200 &&
        setClientes(data.clientesDeSucursal);
    }
    setLoading(false);
  };

  const handleChangeCliente = async (cliente) => {
    await setNewClient(cliente, true);
    dispatch(
      updateProvincia(
        cliente && cliente.cliente.provincia
          ? cliente.cliente.provincia
          : provinciaDefault
            ? provinciaDefault.id
            : null,
      ),
    );
  };

  const getEstadosTributarios = async () => {
    try {
      const response = await request({
        method: "GET",
        url: listEstadoTributario,
      });
      setEstados(response.data);
      const estadoSelected = response.data.filter((e) => e.nombre === "CF")[0];
      setNuevoCliente((prev) => ({
        ...prev,
        idEstadoTributario: estadoSelected.idEstadoTributario,
      }));
    } catch (error) {
      setEstados([]);
      console.error(error);
    }
  };
  return montoMaximo ? (
    <>
      <Grid container spacing={2} style={{ paddingBottom: 15 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Alert severity="info">
            <strong>
              {`
            Para seguir con la venta debe seleccionar un cliente existente o registrar uno nuevo.`}
            </strong>
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <label>Buscar cliente</label>
          <Autocomplete
            fullWidth
            loading={loading}
            value={autocompleteCliente}
            options={clientes}
            filterOptions={(options, state) => options}
            getOptionSelected={(cliente) => cliente.cliente.razonSocial}
            getOptionLabel={(cliente) =>
              cliente.cliente.CUIT || cliente.cliente.numeroDocumento
                ? cliente.cliente.razonSocial +
                  " - " +
                  `${cliente.cliente.CUIT || cliente.cliente.numeroDocumento}`
                : cliente.cliente.razonSocial
            }
            noOptionsText={"No se encontraron resultados"}
            onInputChange={(event, value, reason) => setDescripcion(value)}
            onChange={(event, value, reason) => handleChangeCliente(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                style={{ backgroundColor: "white" }}
                label="Nombre o DNI"
                variant="outlined"
                autoFocus
              />
            )}
            name="buscador_clientes"
          />
        </Grid>
      </Grid>
      <Divider />

      <FormNuevoCliente
        nuevoCliente={{ ...nuevoCliente, cliente: cliente }}
        estados={estados}
        formRef={formRef}
        onSubmit={onSubmit}
        clienteInactivo={clienteInactivo}
      />
    </>
  ) : (
    <Grid container spacing={2} style={{ paddingBottom: 15 }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Alert severity="error">
          <strong>
            Ocurrió un error al obtener el monto máximo de facturación,
            comuníquese con soporte.
          </strong>
        </Alert>
      </Grid>
    </Grid>
  );
}
