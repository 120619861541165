import React from "react";
import { Grid, Typography } from "@material-ui/core";
import TooltipMoreInfoMaterial from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function Totales({ data }) {
  const values = [
    {
      id: 0,
      name: "IVA ventas",
      value: data.ivaVentas,
      size: 17,
      fontWeigth: "",
    },
    {
      id: 1,
      name: "IVA NC ventas",
      value: data.ivaNCVentas * -1,
      size: 17,
      fontWeigth: "",
    },
    {
      id: 2,
      name: "Total débito fiscal (DF)",
      value: data.ivaVentas - data.ivaNCVentas,
      size: 19,
      fontWeigth: "bold",
    },
    {
      id: 3,
      name: "IVA compras",
      value: data.ivaCompras,
      size: 17,
      fontWeigth: "",
    },
    {
      id: 4,
      name: "IVA NC compras",
      value: data.ivaNCCompras * -1,
      size: 17,
      fontWeigth: "",
    },
    {
      id: 5,
      name: "Total crédito fiscal (CF)",
      value: data.ivaCompras - data.ivaNCCompras,
      size: 19,
      fontWeigth: "bold",
    },
  ];

  /**
   * Funcion que se encarga de calcular el resultado fiscal
   * Si el resultado es positivo significa que el debito fiscal es mayor al credito fiscal (saldo a pagar a AFIP)
   * @returns {Number} Resultado del calculo
   */
  const getCalculo = () => {
    let debitoFiscal = data.ivaVentas - data.ivaNCVentas;
    let creditoFiscal = data.ivaCompras - data.ivaNCCompras;

    let resultado = Number((debitoFiscal - creditoFiscal).toFixed(2));

    return resultado;
  };

  /**
   * Funcion que obtiene el resultado fiscal y lo retorna en valor absoluto
   * @returns {Number} Resultado del calculo
   */
  const getResultado = () => {
    let resultado = Math.abs(getCalculo());
    return resultado;
  };

  /**
   * Funcion que retorna el color del resultado fiscal
   * Si es positivo es rojo, si es negativo es verde, si es 0 es el color por defecto
   * @returns {String} Color del resultado fiscal
   */
  const getColorResultadoFiscal = () => {
    let resultado = getCalculo();

    if (resultado > 0) {
      return "#ef5350";
    } else if (resultado < 0) {
      return "#4caf50";
    } else {
      return "";
    }
  };

  /**
   * Funcion que retorna el mensaje del resultado fiscal en base al monto del resultado
   * @returns {String} Mensaje del resultado fiscal
   */
  const getMensajeTooltip = () => {
    let resultado = getCalculo();

    if (resultado > 0) {
      return "Saldo a pagar a AFIP";
    } else if (resultado < 0) {
      return "Saldo a favor en AFIP";
    } else {
      return "";
    }
  };

  return (
    <Grid container spacing={2} style={{ padding: 10 }}>
      <Grid item xs={12} style={{ borderBottom: "solid 1px #dcdcdc" }}>
        <Typography variant="h6" style={{ textAlign: "center" }}>
          Totales
        </Typography>
      </Grid>
      {values.map((item) => (
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Typography
              variant="h6"
              style={{ fontSize: item.size, fontWeight: item.fontWeigth }}
            >
              {item.name.toLocaleUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography
              variant="h6"
              style={{
                fontSize: item.size,
                fontWeight: item.fontWeigth,
                textAlign: "end",
              }}
            >
              $ {item.value.toLocaleString("es-AR")}
            </Typography>
          </Grid>
        </Grid>
      ))}
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className="pt-2">
            <Typography
              variant="h6"
              style={{
                fontSize: 19,
                fontWeight: "bold",
              }}
            >
              DF - CF
            </Typography>
          </div>
        </Grid>
        <Grid item xs={9}>
          <div
            className={"pt-2 justify-content-end"}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Typography
              variant="h6"
              style={{
                fontSize: 19,
                fontWeight: "bold",
                color: getColorResultadoFiscal(),
              }}
            >
              {`RESULTADO: $ ${getResultado().toLocaleString("es-AR")}`}
            </Typography>
            <div className={"pl-1"}>
              <TooltipMoreInfoMaterial
                position={"top"}
                titleTooltip={
                  <h6
                    style={{
                      fontSize: 18,
                      marginBottom: 0,
                      marginTop: 0,
                    }}
                  >
                    {getMensajeTooltip()}
                  </h6>
                }
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
