import React, { useEffect } from "react";
import { Paper, TextField, Box } from "@material-ui/core";
import { Container } from "shards-react";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import ReusableTable from "../../../../components/ait-reusable/Report/ReusableTable";

export default function ListaArticulos({
  articulos,
  remove,
  handleChangeCantidad,
  handleChangePreciosCosto,
  articulosFiltrados,
  setArticulosFiltrados,
}) {
  let columns = [
    "Artículo",
    "Original",
    "Descripción",
    "Cantidad",
    "Precio",
    "Acciones",
  ];

  useEffect(() => {
    if (articulos && articulos.length !== 0) {
      const a = articulos.filter((a) => a.is_deleted === false);
      setArticulosFiltrados(a);
    }
  }, [articulos]);

  useEffect(() => {
    formatArticulos();
  }, [articulos]);

  const formatArticulos = () => {
    return articulosFiltrados.map((articulo, index) => ({
      articulo: articulo.codProveedor || "---",
      original: articulo.codOriginal || "---",
      descripcion: articulo.descripcionProveedor || "---",
      cantidad: (
        <TextField
          style={{ width: "100px" }}
          value={articulo.cantidad}
          name="cantidad"
          type="number"
          variant="outlined"
          size="small"
          onChange={(e) => handleChangeCantidad(e, index)}
        />
      ),
      precio: (
        <TextField
          style={{ width: "100px" }}
          value={articulo.precios.costo || 0}
          name="precio"
          type="number"
          variant="outlined"
          size="small"
          onChange={(e) => handleChangePreciosCosto(e, index)}
        />
      ),
      opciones: (
        <IconButton
          style={{ color: "red", padding: 0 }}
          aria-label="quitar"
          component="span"
          onClick={() => remove(articulo, index)}
        >
          <ClearIcon />
        </IconButton>
      ),
    }));
  };

  return (
    <Container fluid className="main-content-container px-3">
      <Paper style={{ paddingBottom: 5 }}>
        <Box minHeight={275} maxHeight={300} overflow="auto">
          <ReusableTable
            columns={columns}
            noResults={"Aún no se han agregado artículos al pedido"}
            items={formatArticulos()}
          />
        </Box>
      </Paper>
    </Container>
  );
}
