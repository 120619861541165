import { errorNotification } from "../../components/Notifications";
import request from "../../requests/request";
import { actualizarImpuestoVencido } from "../../requests/urls";
import generarPresupuesto from "../../utils/generarTicketFactura/generarPresupuesto";

export const redirect = (action, response) => {
  switch (action) {
    case "print":
      setTimeout(
        () =>
          window.open(
            `/presupuestos/pdf/${response.data.data.id_presupuesto}/`,
          ),
        1500,
      );
      break;
    case "ticket":
      setTimeout(() => generarPresupuesto(response.data.data), 1500);
      break;
    default:
      break;
  }
};

/** Verificar si el cliente posee un impuesto con alicutoa vencida
 *   NOTA: Solo aplica para el cliente Zingaro que utliza API ARBA
 *  ARBA maneja vencimientos, actualizar con el backend y actualizar objeto de frontend
 */
export const verificarImpuestoCliente = async (cliente) => {
  let clienteDeepCopy = { ...cliente };

  if (!cliente) {
    return cliente;
  }

  if (cliente.impuestos.length === 0) {
    return cliente;
  }

  let existeImpuestoConActualizacionApi = cliente.impuestos.find(
    (imp) => imp.tipo_actualizacion === "Api",
  );

  // Si el cliente no tiene ningun impuesto por Api no se actualiza (ARBA ZINGARO )
  if (!existeImpuestoConActualizacionApi) {
    return cliente;
  }

  let impuestoEstaVencido =
    new Date(existeImpuestoConActualizacionApi.fecha_vencimiento) < new Date();

  if (!impuestoEstaVencido) {
    return cliente;
  }

  // Actualizar impuesto del cliente

  try {
    const response = await request({
      method: "PUT",
      url: actualizarImpuestoVencido,
      data: {
        cliente_id: cliente.idCliente.idCliente,
        impuesto_id: existeImpuestoConActualizacionApi.impuesto,
      },
    });
    let impuestoActualizado = response.data;
    const indexImpuestoApi = cliente.impuestos.findIndex(
      (imp) => imp.impuesto === impuestoActualizado.impuesto,
    );
    clienteDeepCopy.impuestos[indexImpuestoApi] = impuestoActualizado;
    return clienteDeepCopy;
  } catch (error) {
    errorNotification(
      `Ocurrió un error al actualizar la alicutoa vencida del impuesto ${existeImpuestoConActualizacionApi.nombre}`,
    );
    return cliente;
  }
};
