export function parseCurrency(num) {
  let valueNumber = Number(num);

  return valueNumber.toLocaleString("es-AR", {
    style: "currency",
    currency: "ARS",
  });
}

/** Formato para estilo de moneda
 * En caso de no se pase un tipo de moneda se utiliza por defecto
 * el formato argentino */
export const formatoMoneda = ({ value, currency = null }) => {
  const valueNumber = Number(value);
  let currencyValue = "ARS";

  if (currency) {
    currencyValue = currency;
  }

  return new Intl.NumberFormat("Es-ar", {
    style: "currency",
    currency: currencyValue,
  }).format(valueNumber);
};
