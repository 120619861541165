import React from "react";
import DetalleVenta from "./DetalleVenta";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import { Box, Grid } from "@material-ui/core";

export default function DetalleCollapse({ selected }) {
  const handleRenderDescripcionItem = (item) => {
    return (
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          style={{ gap: 5 }}
        >
          <p style={{ margin: 0 }}>
            {item.repuesto ? `${item.repuesto.codigo} -` : ""}
          </p>
          <p style={{ margin: 0 }}>
            {`${
              item.descripcion ? item.descripcion : item.repuesto.descripcion
            }`}
          </p>
        </Box>
        {item.descripcionPersonalizada ? (
          <p style={{ margin: 0, fontSize: 12, fontWeight: "bold" }}>
            {item.descripcionPersonalizada}
          </p>
        ) : null}
      </Box>
    );
  };

  const formatDetalles = (venta) => {
    return venta.detalle.map((item) => ({
      cod: (item.repuesto && item.repuesto.codigo) || "---",
      lista_precios: item.nombre_lista_precios || "---",
      descripcion: handleRenderDescripcionItem(item),
      cantidad: item.cantidad,
      subtotal: `$${parseFloat(item.subtotal).toLocaleString("es-AR")}`,
    }));
  };

  return (
    <>
      <DetalleVenta selected={selected} />

      <Grid container spacing={2} style={{ overflow: "auto", maxHeight: 185 }}>
        <Grid item xs={12} lg={12}>
          <ReusableTable
            columns={[
              "Código",
              "L. Precios",
              "Descripcion",
              "Cantidad",
              "Subtotal",
            ]}
            items={formatDetalles(selected)}
            heightStyle={"calc(100vh - 485px)"}
          />
        </Grid>
      </Grid>
    </>
  );
}
