import React from "react";
import { useEffect, useState } from "react";
import {
  calcularSubtotalSinIva,
  calcularMontoDescuento,
  calcularIVA,
  calcularTotalesPorImpuesto,
} from "./utils";

const useAmountCalculator = (presupuesto) => {
  const { montos, cliente, tipoComprobante, impuestos, descuento } =
    presupuesto;

  const [aplicaIVA, setAplicaIVA] = useState(false);
  const [impuestosCliente, setImpuestosCliente] = useState({
    grabadoIIBB: false,
    percepcionMunicipal: false,
  });
  const [montosCalculados, setMontosCalculados] = useState({
    montoImporte: 0,
    montoDescuento: 0,
    montoConDescuentoSinImpuesto: 0,
    montoInteres: 0,
    montoConDescuentoEInteresSinIVAEImp: 0,
    montoIVA: 0,
    montoConIVASinImp: 0,
    montoTotalImpuestos: 0,
    total: 0,
  });
  const [totalesPorImpuesto, setTotalesPorImpuesto] = useState([]);

  useEffect(() => {
    const tieneClienteGrabadoIIBB =
      cliente && cliente.cliente && cliente.cliente.esta_grabado_iibb
        ? true
        : false;

    const aplicanPercepcionesMunicipalesCliente =
      cliente &&
      cliente.cliente &&
      cliente.cliente.aplica_percepcion_impuesto_municipal
        ? true
        : false;

    setImpuestosCliente({
      ...impuestosCliente,
      grabadoIIBB: tieneClienteGrabadoIIBB,
      percepcionMunicipal: aplicanPercepcionesMunicipalesCliente,
    });
  }, [cliente]);

  useEffect(() => {
    const aplicaIva =
      tipoComprobante &&
      (tipoComprobante.nombre === "A" ||
        tipoComprobante.nombre === "FCEM A" ||
        tipoComprobante.nombre === "M")
        ? true
        : false;

    setAplicaIVA(aplicaIva);
  }, [tipoComprobante]);

  useEffect(() => {
    calculoImpuestos();
  }, [montos.subtotal, impuestosCliente, aplicaIVA, descuento]);

  const calculoImpuestos = () => {
    const aplicanImpuestos = Boolean(
      presupuesto.cliente && presupuesto.cliente.impuestos.length > 0,
    );
    let newMontoSubtotal = aplicaIVA
      ? calcularSubtotalSinIva(montos.subtotal)
      : montos.subtotal;

    let impuestosAplicados =
      aplicanImpuestos && aplicaIVA
        ? presupuesto.cliente.impuestos.filter(
            (imp) => montos.subtotal >= Number(imp.minimo_no_imponible),
          )
        : [];
    impuestosAplicados = impuestosAplicados.map((imp) => ({
      ...imp,
      alicuota: Number(imp.alicuota) / 100,
      minimo_no_imponible: Number(imp.minimo_no_imponible),
    }));

    const montoDescuento = calcularMontoDescuento(
      newMontoSubtotal,
      (descuento && descuento.porcentaje) || 0,
    );

    const subtotalConDescuentoSinImpuestos = Number(
      newMontoSubtotal - montoDescuento,
    );

    //No hay interes porque no hay medio de pago
    const montoInteres = 0;

    const montoConDescuentoEInteresSinIvaEImpuestos = Number(
      subtotalConDescuentoSinImpuestos + montoInteres,
    );

    const montoIVA = aplicaIVA
      ? calcularIVA(montoConDescuentoEInteresSinIvaEImpuestos)
      : 0;

    const totalConIvaSinImpuestos = aplicaIVA
      ? Number(montoConDescuentoEInteresSinIvaEImpuestos * 1.21)
      : montoConDescuentoEInteresSinIvaEImpuestos;

    const totalesPorImpuesto = aplicanImpuestos
      ? calcularTotalesPorImpuesto(
          impuestosAplicados,
          montoConDescuentoEInteresSinIvaEImpuestos,
        )
      : [];

    const montoTotalEnImpuestos = Number(
      totalesPorImpuesto.reduce((acc, imp) => {
        return Number(imp.monto) + acc;
      }, 0),
    );

    const montoTotalConIvaEImpuestos = Number(
      montoTotalEnImpuestos + totalConIvaSinImpuestos,
    );

    setMontosCalculados({
      ...montosCalculados,
      montoImporte: Number(newMontoSubtotal.toFixed(2)),
      montoDescuento: Number(montoDescuento.toFixed(2)),
      montoConDescuentoSinImpuesto: Number(
        subtotalConDescuentoSinImpuestos.toFixed(2),
      ),
      montoInteres: Number(montoInteres.toFixed(2)),
      montoConDescuentoEInteresSinIVAEImp: Number(
        montoConDescuentoEInteresSinIvaEImpuestos.toFixed(2),
      ),
      montoIVA: Number(montoIVA.toFixed(2)),
      montoConIVASinImp: Number(totalConIvaSinImpuestos.toFixed(2)),
      montoTotalImpuestos: Number(montoTotalEnImpuestos.toFixed(2)),
      total: Number(montoTotalConIvaEImpuestos.toFixed(2)),
    });

    setTotalesPorImpuesto(totalesPorImpuesto);
  };

  return {
    useAmount: {
      aplicaIVA,
      impuestosCliente,
      montosCalculados,
      totalesPorImpuesto,
    },
  };
};

export default useAmountCalculator;
