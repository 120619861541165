import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Box,
  makeStyles,
  Tabs,
  Tab,
} from "@material-ui/core";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import { DetallePagoContent } from "./DetallePagoContent";
import { DetalleNcDescuentoContent } from "./DetalleNcDescuentoContent";

const useStyles = makeStyles({
  subtitle: {
    fontWeight: "bold",
    marginRight: 5,
  },
  borderlessRow: {
    borderBottom: 0,
  },
  root: {
    minWidth: 600,
  },
});

export default function ModalDetallesPago({
  pago,
  open,
  close,
  title,
  notasCreditoPagoSinDesc,
  notasCreditoPago,
}) {
  const [tabValue, setTabValue] = React.useState(0);
  const classes = useStyles(tabValue);

  const tabs = [
    { id: 0, name: "Detalle", disabled: false },
    {
      id: 1,
      name: `Notas de Crédito Descuento asociadas (${notasCreditoPago.length})`,

      disabled: !notasCreditoPago.length,
    },
  ];
  const getComponentsTabs = () => {
    switch (tabValue) {
      case 0:
        return (
          <DetallePagoContent
            pago={pago}
            classes={classes}
            notasCreditoPagoSinDesc={notasCreditoPagoSinDesc}
          />
        );
      case 1:
        return (
          <DetalleNcDescuentoContent
            classes={classes}
            notasCreditoPago={notasCreditoPago}
          />
        );
      default:
        return null;
    }
  };

  const handleOnChangeTab = (e, value) => {
    setTabValue(value);
  };

  return (
    <Dialog
      open={open}
      maxWidth={"md"}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: tabValue === 1 && classes.root }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Tabs
            indicatorColor="primary"
            value={tabValue}
            textColor="primary"
            onChange={handleOnChangeTab}
            aria-label="simple tabs example"
          >
            {tabs.map((tab) => (
              <Tab
                disabled={tab.disabled}
                key={tab.id}
                label={tab.name}
                value={tab.id}
              />
            ))}
          </Tabs>
          <Box pb={1}>
            <Divider />
          </Box>
          {getComponentsTabs()}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <ButtonCancelar click={close} message="Salir" />
      </DialogActions>
    </Dialog>
  );
}
