import React from "react";
import ModalConfirm from "../../../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import { Box, Typography } from "@material-ui/core";
import MovimientoMuestra from "./MovimientoMuestra";
import { useBajaMovimiento } from "../../../services/movimiento";
import { useQueryClient } from "react-query";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";

export default function ModalEliminarMovimiento({
  open,
  handleClose,
  movimiento,
}) {
  const queryClient = useQueryClient();
  const bajaMovimiento = useBajaMovimiento({
    queryParams: {
      movimiento_id: movimiento.id,
    },
    queryProps: {
      onSuccess: () => {
        queryClient.invalidateQueries("obtenerCuenta");
        successNotification("Movimiento eliminado con éxito");
        handleClose();
      },
      onError: (error) => {
        errorNotification("Error al eliminar el movimiento");
        console.log(error);
      },
    },
  });

  const handleSubmit = () => {
    bajaMovimiento.mutate();
  };
  return (
    <ModalConfirm
      open={open}
      title={`Eliminar movimiento N°${movimiento.id}`}
      buttons={{
        cancel: "Cancelar",
        submit: "Eliminar",
      }}
      body={
        <Box mt={2}>
          <Typography variant="body1">
            ¿Realmente desea eliminar el movimiento N°{movimiento.id}?
          </Typography>
          <MovimientoMuestra movimiento={movimiento} />
        </Box>
      }
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    />
  );
}
