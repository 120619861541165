import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import useComprobantes from "../../../../customHooks/useComprobantes";
import { useSelector } from "react-redux";
import useFacturar from "./useFacturar";
import request from "../../../../requests/request";
import ModalReutilizable from "../../../../components/ait-reusable/Modal";

const index = ({
  open,
  toggle,
  updateVentas,
  comprobante,
  setDataErrorAFIP,
}) => {
  const { configGeneral } = useSelector((state) => state.configGeneral);

  const [cbu, setCbu] = useState(configGeneral.cbu);
  const [alias, setAlias] = useState(configGeneral.alias);
  const [disabled, setDisabled] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [tipoComprobante, setTipoComprobante] = useState(null);
  const [vencimientoDelPago, setVencimientoDelPago] = useState(new Date());
  const [opcionTransferencia, setOpcionTransferencia] = useState(null);
  const [opcionesTransferencia, setOpcionesTransferencia] = useState([]);

  const [selectedDate, setSelectedDate] = useState(
    moment(new Date())
      .add(
        comprobante.pagos_cliente.some(
          (e) => e.medio_pago && e.medio_pago.nombre === "Cuenta Corriente",
        )
          ? configGeneral.dias_facturas_vencimiento
          : 0,
        "days",
      )
      .format("YYYY-MM-DD"),
  );

  const puedeFacturarFcem =
    Number(comprobante.montoTotal) >= 299555 &&
    comprobante.cliente &&
    comprobante.cliente.estadoTributario !== "Monotributista";

  const update = (venta) => {
    updateVentas(venta);
    toggle(null);
  };

  const closeModalErrorAFIP = (dev, nroFactura, type) => {
    let idVentaError = comprobante.id || comprobante.idVenta || null;
    setDataErrorAFIP((prev) => ({
      ...prev,
      devoluciones: dev,
      nroFactura: nroFactura,
      type: type,
      open: true,
      venta: idVentaError,
    }));
    toggle(null);
  };

  const { comprobantes } = useComprobantes(
    comprobante.cliente ? comprobante.cliente.estadoTributario : null,
    false,
    setOpenBackdrop,
  );
  const { handlePostFactura } = useFacturar(
    update,
    toggle,
    comprobante.id || comprobante.idVenta,
    { disabled, setDisabled },
    setOpenBackdrop,
    selectedDate,
    closeModalErrorAFIP,
  );

  const handleChangeComprobante = (event) => {
    let nombreComprobante = event.target.value;
    let comprobanteObjectIdx = comprobantes.findIndex(
      (value) => value.nombre === nombreComprobante,
    );
    setTipoComprobante(comprobantes[comprobanteObjectIdx]);
    setOpenBackdrop(false);
  };

  const handleGetOpcionesTransferencia = async () => {
    try {
      const response = await request({
        method: "GET",
        url: "/api/facturacion/opciones-transferencia/",
      });
      setOpcionesTransferencia(response.data);
      setOpcionTransferencia(response.data[0].nombre);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFiltrarComprobantes = (comprobantes) => {
    let comprobantesFiltrados = comprobantes;
    if (puedeFacturarFcem) {
      return comprobantes.map((option, index) => (
        <option key={index} value={option.nombre}>
          {option.nombre}
        </option>
      ));
    } else if (!puedeFacturarFcem) {
      return comprobantesFiltrados
        .filter((comprobante) => comprobante.nombre !== "FCEM A")
        .map((option, index) => (
          <option key={index} value={option.nombre}>
            {option.nombre}
          </option>
        ));
    }
  };

  const handleDateChange = (event) => {
    const inputDate = new Date(event.target.value);
    setVencimientoDelPago(inputDate);
  };

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  useEffect(() => {
    handleGetOpcionesTransferencia();
  }, []);

  useEffect(() => {
    if (opcionesTransferencia.length) {
      setOpcionTransferencia(opcionesTransferencia[0].id);
    }
  }, [opcionesTransferencia]);

  useEffect(() => {
    if (puedeFacturarFcem) {
      setTipoComprobante(comprobantes[0]);
    } else {
      const comprobantesSinFCEMA = [...comprobantes].filter(
        (comprobante) => comprobante.nombre !== "FCEM A",
      );
      setTipoComprobante(comprobantesSinFCEMA[0]);
    }
  }, [comprobantes]);

  return (
    <ModalReutilizable
      open={open}
      handleClose={() => toggle(null)}
      title={`Generar factura para comprobante nro. ${
        comprobante ? comprobante.id || comprobante.idVenta : ""
      }`}
      content={
        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{ paddingTop: 10, paddingBottom: 10 }}
        >
          <Grid item xs={10} lg={10}>
            <FormControl size={"small"} fullWidth variant="outlined">
              <InputLabel id="lbl-comprobante">Tipo de factura</InputLabel>
              <Select
                id="select-comprobante"
                value={tipoComprobante ? tipoComprobante.nombre : ""}
                onChange={handleChangeComprobante}
                label="Tipo de factura"
              >
                {handleFiltrarComprobantes(comprobantes)}
              </Select>
            </FormControl>
          </Grid>

          {tipoComprobante && tipoComprobante.nombre === "FCEM A" ? (
            <>
              <Grid item xs={10} lg={10}>
                <TextField
                  style={{ backgroundColor: "white" }}
                  select
                  fullWidth
                  size="small"
                  label="Opción Transferencia"
                  value={opcionTransferencia}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setOpcionTransferencia(e.target.value)}
                  variant="outlined"
                >
                  {opcionesTransferencia.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      name={option.nombre}
                    >
                      {option.nombre}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={10} lg={10}>
                <TextField
                  style={{ backgroundColor: "white" }}
                  fullWidth
                  size="small"
                  label="CBU"
                  defaultValue={configGeneral.cbu}
                  value={cbu}
                  disabled
                  onChange={(e) => setCbu(e.target.value)}
                  variant="outlined"
                  name="fechavencimiento"
                />
              </Grid>
              <Grid item xs={10} lg={10}>
                <TextField
                  style={{ backgroundColor: "white" }}
                  fullWidth
                  size="small"
                  label="Alias"
                  defaultValue={configGeneral.alias}
                  value={alias}
                  disabled
                  onChange={(e) => setAlias(e.target.value)}
                  variant="outlined"
                  name="fechavencimiento"
                />
              </Grid>
              <Grid item xs={10} lg={10}>
                <TextField
                  id="date"
                  size="small"
                  variant="outlined"
                  label="Vencimiento del pago"
                  type="date"
                  style={{ background: "#ffffff", width: "100%" }}
                  value={formatDate(vencimientoDelPago)}
                  onChange={(e) => handleDateChange(e)}
                  defaultValue={formatDate(vencimientoDelPago)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: formatDate(
                      new Date(new Date().setDate(new Date().getDate() - 5)),
                    ),
                    max: formatDate(
                      new Date(new Date().setDate(new Date().getDate() + 1)),
                    ),
                  }}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={10} lg={10}>
              <TextField
                variant="outlined"
                fullWidth
                id="date"
                size="small"
                label="Fecha de Vencimiento"
                type="date"
                name="fechaVencimiento"
                value={selectedDate}
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          )}
        </Grid>
      }
      actions={
        <>
          <ButtonCancelar click={() => toggle(null)} message={"Cancelar"} />
          <ButtonAceptar
            click={() =>
              !disabled &&
              handlePostFactura(
                tipoComprobante.idTipoFactura,
                tipoComprobante,
                vencimientoDelPago,
                cbu,
                alias,
                opcionTransferencia,
              )
            }
            message={"Generar"}
            disabled={disabled}
          />
        </>
      }
      centerActions={true}
      size={"xs"}
      openBackdrop={openBackdrop}
    />
  );
};

export default index;
