import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { Line_A4_table, Line_A4_table_white } from "../raya";

export default function BodyTable({
  descripcion,
  cant,
  precio,
  subtotal,
  noLine,
  remito,
  listaPrecio = null,
  config,
}) {
  return (
    <View style={{ paddingTop: 5 }}>
      <View style={styles.containerRowBodyTable}>
        {listaPrecio && config.incluir_lista_precios_venta && (
          <View style={(styles.containerColumn, { width: 50 })}>
            <Text style={styles.textLeftTable}>{listaPrecio}</Text>
          </View>
        )}
        <View style={(styles.containerColumn, { width: 200 })}>
          <Text style={styles.textLeftTable}>
            {`${
              descripcion.substr(0, 50).trim() +
              (descripcion.length > 50 ? "..." : "")
            }` || "---"}
          </Text>
        </View>
        <View style={(styles.containerColumn, { width: 30 })}>
          <Text style={styles.textCenterTable}>{cant}</Text>
        </View>

        <View style={(styles.containerColumn, { width: 60 })}>
          <Text style={styles.textCenterTable}>
            {!remito ? `$ ${precio}` : "---"}
          </Text>
        </View>

        <View style={(styles.containerColumn2, { width: 60 })}>
          <Text style={styles.textRightTable}>
            {!remito ? `$ ${subtotal}` : "---"}
          </Text>
        </View>
      </View>
      <View style={{ marginTop: -15 }}>
        {noLine ? <Line_A4_table_white /> : <Line_A4_table />}
      </View>
    </View>
  );
}
