import moment from "moment";
import sinFoto from "../images/sinFoto.png";

export const publicacionFromApi = (data) => {
  return {
    id: data.woo_data.id,
    dataApi: data.api_data,
    vinculada: data.linked,
    fechaModificacion: moment(data.woo_data.date_modified).format(
      "DD/MM/YYYY HH:mm",
    ),
    descripcion:
      data.woo_data.description === ""
        ? null
        : formatDescription(data.woo_data.description),
    descripcionCorta:
      data.woo_data.short_description === ""
        ? null
        : formatDescription(data.woo_data.short_description),
    sku: data.woo_data.sku,
    precio: data.woo_data.price,
    tipo: data.woo_data.type,
    nombre: data.woo_data.name,
    imagen:
      data.woo_data.images.length > 0 ? data.woo_data.images[0].src : sinFoto,
    link: data.woo_data.permalink,
    stock: data.woo_data.stock_quantity,
    manejaStock: data.woo_data.manage_stock,
  };
};

export const publicacionesFromApi = (data) => {
  return {
    count: data.count,
    results: data.results.map((publicacion) => ({
      id: publicacion.id,
      nombre: publicacion.name,
      descripcion:
        publicacion.description === ""
          ? null
          : formatDescription(publicacion.description),
      descripcionCorta:
        publicacion.short_description === ""
          ? null
          : formatDescription(publicacion.short_description),
      precio: `$${parseFloat(publicacion.price).toLocaleString("es-AR")}`,
      imagen:
        publicacion.images.length > 0 ? publicacion.images[0].src : sinFoto,
      // cantDisponible: publicacion.available_quantity,
      vinculada: publicacion.linked,
      tipo: publicacion.type,
      link: publicacion.permalink,
      stock: publicacion.stock_quantity,
      fechaModificacion: moment(publicacion.date_modified).format(
        "DD/MM/YYYY HH:mm",
      ),
      sku: publicacion.sku,
    })),
  };
};

const formatDescription = (data) => {
  if (data.includes("<p>")) {
    let firstData = data.split("<p>");
    if (firstData[1].includes("</p>")) {
      let newData = firstData[1].split("</p>");
      return newData[0];
    } else {
      return firstData[1];
    }
  } else {
    return data;
  }
};

export const publicacionToApi = (
  isEdit,
  data,
  articulosSelected,
  idProducto,
  publicacion,
) => {
  let newData = {};
  if (!isEdit) {
    newData = {
      item_id: articulosSelected[0].idRepuestoProveedor, //Modificar esto cuando sean muchos articulos
      woo_product_id: idProducto,
      pivot: data.manejaPrecio
        ? data.tipo === 0
          ? Number(data.porcentaje)
          : Number(data.porcentaje) * -1
        : 0,
      price: data.manejaPrecio ? Number(data.precioTotal) : 0,
      sync_price: data.manejaPrecio,
      sync_stock: data.stock,
      stock: data.stock ? 1 : 0,
    };
  } else {
    newData = {
      bonding_id: publicacion.dataApi.id,
      pivot: data.manejaPrecio
        ? data.tipo === 0
          ? Number(data.porcentaje)
          : Number(data.porcentaje) * -1
        : 0,
      price: data.manejaPrecio ? Number(data.precioTotal) : 0,
      sync_price: data.manejaPrecio,
      sync_stock: data.stock,
      stock: data.stock ? 1 : 0,
    };
  }
  return newData;
};
