import React from "react";
import { useState, createContext, useContext, useRef } from "react";
import { getData } from "../views/Informe/Ventas/utils";
import axios from "axios";

export const CacheRequestContext = createContext(null);

function obtenerCacheLocalStorage({ key }) {
  const cache = localStorage.getItem(JSON.stringify(key));
  return cache ? JSON.parse(cache) : null;
}

function guardarCacheLocalStorage({ key, values }) {
  localStorage.setItem(JSON.stringify(key), JSON.stringify(values));
}

function mergeCacheMedioPagoVenta({ actual, nuevo }) {
  /**
   * La función `mergeCacheMedioPagoVenta` fusiona los objetos `actual` y `nuevo` sumando los valores de
   * ciertos campos en la propiedad `totalesMedioPago.data.data` y actualizando el campo
   * `pk_ultima_venta`.
   * :return: el caché fusionado del medio pago venta, que incluye los valores actualizados del objeto
   * "nuevo" y los valores existentes del objeto "actual".
   */
  let result = { ...nuevo };
  const campos = Object.keys(nuevo.totalesMedioPago.data.data).filter(
    (key) => key != "pk_ultima_venta",
  );
  let data = campos.reduce((acc, key) => {
    acc[key] =
      actual.totalesMedioPago.data.data[key] +
      nuevo.totalesMedioPago.data.data[key];
    return acc;
  }, {});
  data = {
    ...data,
    pk_ultima_venta: nuevo.totalesMedioPago.data.data.pk_ultima_venta,
  };
  result.totalesMedioPago.data.data = data;
  return result;
}

export const CacheRequestContextProvider = ({ children }) => {
  /**
   * La función `CacheRequestContextProvider` es un componente de React que proporciona un mecanismo de
   * almacenamiento en caché para realizar solicitudes de API y almacenar los datos de respuesta en el
   * almacenamiento local.
   * :return: Se está devolviendo el componente `CacheRequestContextProvider`.
   */
  const cancelTokenSource = useRef(null);

  async function obtenerTotalesMedioPagoVentas(...params) {
    let response = null;
    // Cancelar la petición anterior si existe
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Petición cancelada debido a una nueva petición.",
      );
    }
    // Generar un nuevo token de cancelación para la petición
    cancelTokenSource.current = axios.CancelToken.source();

    const cache = obtenerCacheLocalStorage({ key: params });
    if (cache && Object.keys(cache).length > 0) {
      const ultimaVenta = cache.totalesMedioPago.data.data.pk_ultima_venta;
      try {
        response = await getData(...params, ultimaVenta, cancelTokenSource);
        let results = mergeCacheMedioPagoVenta({
          actual: cache,
          nuevo: response,
        });
        guardarCacheLocalStorage({
          key: params,
          values: results,
        });
        return results;
      } catch {
        return cache;
      }
    }

    try {
      response = await getData(...params, null, cancelTokenSource);

      guardarCacheLocalStorage({
        key: params,
        values: response,
      });
    } catch (error) {}
    return response;
  }

  return (
    <CacheRequestContext.Provider value={{ obtenerTotalesMedioPagoVentas }}>
      {children}
    </CacheRequestContext.Provider>
  );
};
