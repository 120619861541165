import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { parseCurrency } from "../../../../../utils/parsers";
import { Line_A4_table, Line_A4_table_white } from "../../Components/raya";

export default function BodyTablePresupuesto({
  descripcion,
  cant,
  precioVenta,
  precioCosto,
  precioLista,
  listaPrecios,
  subtotal,
  noLine,
  config,
}) {
  return (
    <View style={{ paddingTop: 5 }}>
      <View style={styles.containerRowBodyTable}>
        <View style={(styles.containerColumn, { width: 200 })}>
          <Text style={styles.textLeftTable}>
            {`${
              descripcion.substr(0, 50).trim() +
              (descripcion.length > 50 ? "..." : "")
            }` || "---"}
          </Text>
        </View>
        <View style={(styles.containerColumn, { width: 30 })}>
          <Text style={styles.textCenterTable}>{cant}</Text>
        </View>
        {config.incluir_precio_costo_presupuesto ? (
          <View style={(styles.containerColumn, { width: 60 })}>
            <Text style={styles.textCenterTable}>{`$ ${precioCosto}`}</Text>
          </View>
        ) : null}

        {config.incluir_precio_lista_presupuesto ? (
          <View style={(styles.containerColumn, { width: 60 })}>
            <Text style={styles.textCenterTable}>{`$ ${precioLista}`}</Text>
          </View>
        ) : null}

        {config.incluir_precio_venta_presupuesto ? (
          <View style={(styles.containerColumn, { width: 60 })}>
            <Text style={styles.textCenterTable}>{`$ ${precioVenta}`}</Text>
          </View>
        ) : null}

        {config.incluir_lista_precios_presupuesto ? (
          <View style={(styles.containerColumn, { width: 60 })}>
            <Text style={styles.textCenterTable}>{listaPrecios}</Text>
          </View>
        ) : null}

        <View style={(styles.containerColumn2, { width: 60 })}>
          <Text style={styles.textRightTable}>{`$ ${subtotal}`}</Text>
        </View>
      </View>
      <View style={{ marginTop: -15 }}>
        {noLine ? <Line_A4_table_white /> : <Line_A4_table />}
      </View>
    </View>
  );
}
