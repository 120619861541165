import React, { useState } from "react";
import {
  Dialog,
  Divider,
  Backdrop,
  TextField,
  Typography,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  DialogContentText,
} from "@material-ui/core";
import { errorNotification } from "../../../../Notifications";
import { postVerificarClaveMaestraService } from "../../../../../services/sucursal";
import ButtonAceptar from "../../../Button/ButtonAceptar";
import ButtonCancelar from "../../../Button/ButtonCancelar";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "500px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ModalIngresarClave({
  open,
  close,
  setComponenteProtegido,
}) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [inputClaveMaestra, setInputClaveMaestra] = useState("");

  const handleVerificarClaveMaestra = async () => {
    setIsLoading(true);
    try {
      const response =
        await postVerificarClaveMaestraService(inputClaveMaestra);

      if (response.status === 200) {
        setComponenteProtegido(false);
      }
    } catch (error) {
      console.log(error);
      errorNotification("Contraseña incorrecta");
    }
    setIsLoading(false);
  };

  return (
    <Dialog open={open} onClose={close} classes={{ paper: classes.root }}>
      <DialogTitle>Clave maestra</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText className="my-4">
          <Typography className="mb-2" style={{ fontWeight: 500 }}>
            Para completar esta acción por favor ingresa la clave maestra de la
            sucursal.
          </Typography>
          <TextField
            fullWidth
            size="small"
            type="password"
            variant="outlined"
            disabled={isLoading}
            value={inputClaveMaestra}
            label="Ingrese su clave maestra"
            onChange={(e) => setInputClaveMaestra(e.target.value)}
          />
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <ButtonCancelar click={close} message="CANCELAR" />
        <ButtonAceptar
          message="ACEPTAR"
          disabled={!inputClaveMaestra}
          click={handleVerificarClaveMaestra}
        />
      </DialogActions>
      {isLoading && (
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Dialog>
  );
}
