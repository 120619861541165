import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import {
  Button,
  Card,
  Container,
  Grid,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useSelector } from "react-redux";
import TablaFacturas from "./Components/TablaFacturas";
import {
  getVentaPorId,
  deleteVenta,
  formatFacturasImpagas,
  getNewDataDetalle,
} from "./Components/utils";
import request from "../../requests/request";
import {
  consultarDetalleVenta,
  getRecibosOrdenes,
  listFacturasAdeudadas,
  getVentasInFactAgrupada,
} from "../../requests/urls";
import ModalDetalle from "./Components/ModalDetalle";
import { Showmodal } from "../../components/ait-reusable/PDF/Components/PreviewPdf";
import ResumenDeuda from "./Components/ResumenDeuda";
import { getVenta } from "../Venta/utils";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import {
  successNotification,
  errorNotification,
} from "../../components/Notifications";
import TooltipComision, {
  TooltipDcto,
} from "../Clientes/Components/TooltipsCliente";
import EncabezadoPagoFacturas from "./Components/EncabezadoPagoFacturas";
import ModalCargaSaldo from "./Components/ModalCargaSaldo";
import ModalNotaCredito from "../../components/ait-reusable/ModalNotaCredito";
import GoBackCliente from "../Clientes/GoBackCliente";
import ModalConfirm from "../../components/ait-reusable/ModalConfirm/ModalConfirm";
import BotonesResumenDeuda from "./Components/BotonesResumenDeuda";
import ModalWhatsApp from "../../components/ait-reusable/ModalWhatsApp/ModalWhatsApp";
import { getPdfBlob } from "../../components/ait-reusable/ModalWhatsApp/uploadPdf";
import { ModalFechaVencimiento } from "./Components/ModalFechaVencimiento";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../components/ait-reusable/HandlePdf/handlePdf";
import { useSearchEmpleado } from "../../customHooks/useSearchEmpleado";
import useArticlesToPDF from "../../customHooks/useArticlesToPDF";
import { ModalContentAnulacion } from "../Informe/Ventas/ModalContentAnulacion";
import { formatDataObsNCToBack } from "../../components/ait-reusable/ObservacionNotaCreditoVenta/utils";
import PagoFacturasAdeudadas from "./Components/PagoFacturas/index";
import InformeRemitos from "../Informe/Remitos";
import ModalAnulacionFactAgrupada from "../../components/ait-reusable/ModalAnulacionFactAgrupada";
import useVerificarPermiso from "../../customHooks/useVerificarPermisos";
import { PERMISOS } from "../../constantes";
import { initialPdf } from "../Informe/NotaDeCredito/utils";
import ComponenteProtegido from "../../components/ait-reusable/ComponenteProtegido/ComponenteProtegido";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  notaCredito: {
    color: theme.palette.secondary.main,
  },
}));

const AntTabs = withStyles({
  root: {
    backgroundColor: "#ffff",
    borderBottom: "1px solid #ffff",
  },
  indicator: {
    backgroundColor: "rgb(0, 123, 255)",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    fontWeight: 700,
    paddingTop: 0,
    paddingBottom: 0,
    "&:hover": {
      color: "rgb(0, 123, 255)",
      opacity: 1,
    },
    "&$selected": {
      color: "#ffff",
      backgroundColor: "rgb(0, 123, 255)",
      fontWeight: 700,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function FacturasAdeudadas(props) {
  const classes = useStyles();
  const { comprobante_pdf } = useSelector((store) => store.pDF);
  const { configGeneral } = useSelector((store) => store.configGeneral);
  const [modalAnular, setModalAnular] = useState(false);
  const [modalDevolucion, setModalDevolucion] = useState(false);
  const [printComprobante, setPrintComprobante] = useState(null);
  const [openModaCargarSaldo, setOpenModalCargarSaldo] = useState(false);
  const [ventas, setVentas] = useState([]);
  const [selection, setSelection] = useState([]);
  const [form, setForm] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [descripcion, setDescripcion] = useState("");
  const [modalFechaVencimiento, setModalFechaVencimiento] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalDetalle, setModalDetalle] = useState(false);
  const [preview, setpreview] = useState(false);
  const [ventaSelected, setVentaSelected] = useState(null);
  const [ventaPdf, setventaPdf] = useState([]);
  const [tipoImpresion, settipoImpresion] = useState("");
  const [updateResumen, setupdateResumen] = useState(false);
  const [openBackDrop, setOpenBackDrop] = React.useState(false);
  const [newMontos, setNewMontos] = useState({
    subtotal: 0,
    descuento: 0,
    descuentoUnico: 0,
  });
  const [updatebilletera, setUpdatebilletera] = useState(false);
  const [disabledSelectRecargo, setDisabledSelectRecargo] = useState(false);
  const [urlPdf, setUrlPdf] = useState(null);
  const [idMedioPago, setIdMedioPago] = useState(null);
  const [valueFcemA, setValueFcemA] = useState("N");
  const [pagination, setPagination] = useState({
    page: 1,
    num_page: 0,
    num_items: 0,
    next_page: null,
  });
  const [openModalComprobanteWhatsapp, setOpenModalComprobanteWhatsapp] =
    useState(false);
  const [pdfDataFetched, setDataPdfFetched] = useState(null);
  const [montoBilletera, setMontoBilletera] = useState(0);
  const [disabledSelectSinRecargo, setDisabledSelectSinRecargo] =
    useState(false);
  const [conComision, setConComision] = useState(0);
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const [observaciones, setObservaciones] = useState([{ id: null, value: "" }]); //Siempre debe tener ese primer elemento ya que es donde se guarda la obs escrita
  const [isPago, setIsPago] = useState(false);
  const [dataErrorAFIP, setDataErrorAFIP] = React.useState({
    devoluciones: [],
    nroFactura: null,
    type: null,
    open: false,
    venta: null,
  });
  const [valueTab, setValueTab] = useState(0);
  const [modalPdf, setModalPdf] = React.useState(initialPdf);

  const { tab } = useParams();
  const [ventasAgrupadas, setVentasAgrupadas] = useState([]);
  const { empleado } = useSelector((state) => state.loginReducer);
  useVerificarPermiso(empleado, PERMISOS.CLIENTES);

  let tabs = [];

  useEffect(() => {
    if (cliente) {
      tabs = [
        {
          value: `/facturas-adeudadas/${cliente.idClienteDeSucursal}`,
          label: "Comprobantes pendientes",
        },
        { value: "/informe/remitos", label: "Remitos" },
      ];
    } else {
      tabs = [
        {
          value: "",
          label: "Comprobantes pendientes",
        },
        { value: "/informe/remitos", label: "Remitos" },
      ];
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setValueTab(newValue);
  };

  let columns = [
    "NC/DEV Asociadas",
    "N° Referencia",
    <DateRangeIcon style={{ fontSize: 20 }} />,
    "Fecha Vencimiento",
    "Deuda",
    "Descuento",
    "Descripción",
    " -- ",
  ];

  let { idCliente } = useParams();
  const { articlesPDF } = useArticlesToPDF();

  useEffect(() => {
    if (descripcion.length !== 0) {
      setSuggestions(
        ventas.filter(
          (venta) =>
            venta.descripcion &&
            venta.descripcion
              .toLowerCase()
              .startsWith(descripcion.toLowerCase()),
        ),
      );
    } else {
      setSuggestions(ventas);
    }
  }, [descripcion]);

  useEffect(() => {
    RefreshMontos();
  }, [updateResumen]);
  const RefreshMontos = () => {
    if (updateResumen)
      setTimeout(() => {
        setupdateResumen(false);
      }, 180);
  };
  const getFacturasAdeudadas = async () => {
    setVentas([]);
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: listFacturasAdeudadas(idCliente),
        params: {
          page: pagination.page,
        },
      });
      handleResponseFacturasAdeudadas(response);
    } catch (error) {
      console.error(error);
      props.history.goBack();
    }
  };

  const handleModalFechaVencimiento = (venta) => {
    setVentaSelected(venta);
    setModalFechaVencimiento(true);
  };

  const handleResponseFacturasAdeudadas = (response) => {
    if (response.status === 200) {
      setCliente(response.data.data.items.map((d) => d.cliente)[0]);
      setVentas(response.data.data.items);
      setSuggestions(response.data.data.items);

      setPagination({
        ...pagination,
        next_page: null,
        num_items: response.data.data.num_items,
        num_pages: response.data.data.num_pages,
      });
    } else {
      setVentas([]);
      setSuggestions([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getFacturasAdeudadas();
  }, [pagination.page]);

  async function toggleModalDetalle() {
    setModalDetalle(!modalDetalle);
    //El set y el if se ejecutan al mismo tiempo, por lo cual el if valida el valor inicial no el modificado por el setModalDetalle.
    if (modalDetalle) {
      setVentaSelected(null);
    }
  }

  const setComprobanteDetalle = async (venta) => {
    handleGetDetalleFactura(venta.id);
  };

  const handleGetDetalleFactura = async (idVenta, tipo) => {
    try {
      const response = await request({
        method: "GET",
        url: consultarDetalleVenta,
        params: { idVenta: `${idVenta}` },
      });
      handleResponseDetalleFactura(response, tipo);
    } catch (error) {
      console.error(error);
    }
  };
  const handleResponseDetalleFactura = async (response, tipo) => {
    if (response.status === 200) {
      if ((await tipo) !== "devolucion") setModalDetalle(!modalDetalle);
      setVentaSelected(response.data);
    } else {
      setVentaSelected(null);
    }
  };
  const printPdf = async (id, tipo) => {
    setOpenBackDrop(true);
    if ((await comprobante_pdf.length) > 0) {
      if (id !== -1) {
        comprobante_pdf.map((venta) => {
          if (venta.detalle[0].idVenta === id) {
            settipoImpresion(tipo);
            setventaPdf([venta]);
            setOpenBackDrop(false);
            setpreview(true);
          }
        });
      } else {
        settipoImpresion(tipo);
        setventaPdf(comprobante_pdf);
        setOpenBackDrop(false);
        setpreview(true);
      }
    }
  };

  const generarPdfVenta = async (tipo, id, isFCEMA = false) => {
    setOpenBackDrop(true);
    const response = await getVenta(id);
    let newData = articlesPDF.getArticlesPDF(
      response.data.data,
      configGeneral,
      tipo,
      false,
      null,
    );
    const blobPdf = await getBlobPdf(
      tipo,
      newData,
      configGeneral,
      false,
      isFCEMA,
    );
    if (configGeneral.previsualizacion) {
      imprimirPdf(blobPdf);
    } else {
      settipoImpresion(tipo);
      setventaPdf(newData);
      setpreview(true);
      imprimirPdf(blobPdf);
    }
    setOpenBackDrop(false);
  };
  const printComprobanteCargaSaldo = async (pago, tipo = "billeteraTicket") => {
    try {
      const response = await request({
        method: "GET",
        url: getRecibosOrdenes,
        params: {
          orden_carga_saldo_id: pago,
        },
      });
      settipoImpresion(tipo);
      setventaPdf(Array(response.data));
      setpreview(true);
    } catch (error) {
      setventaPdf([]);
    }
  };

  const toggle = useCallback(() => {
    setpreview(false);
  }, []);

  useEffect(() => {
    printComprobante &&
      printComprobanteCargaSaldo(printComprobante).then(() =>
        setPrintComprobante(null),
      );
  }, [printComprobante]);

  const handleClose = () => {
    setOpenBackDrop(false);
  };
  const anularVenta = () => {
    deleteVenta(
      ventaSelected.idVenta,
      idMedioPago,
      valueFcemA,
      setOpenBackDrop,
      formatDataObsNCToBack(observaciones),
      actionsErrorAFIP,
      setObservaciones,
      setModalAnular,
      ventaSelected,
      setDataErrorAFIP,
    ).then((res) => {
      if (res && res.status === 201) {
        successNotification("La venta se anuló correctamente");
        setObservaciones([{ id: null, value: "" }]);
        setModalAnular(false);
        setVentaSelected(null);
        getFacturasAdeudadas();
      }
    });
  };

  const actionsErrorAFIP = () => {
    setObservaciones([{ id: null, value: "" }]);
    setModalAnular(false);
    setVentaSelected(null);
    getFacturasAdeudadas();
  };

  const getValueSaldar = () => {
    let dataReturn = "";

    let mTotal = newMontos.subtotal - newMontos.descuento;

    /**
     * Si disabledSelectSinRecargo las ventas seleccionadas son con descuento
     *
     * Va a validar el saldo disponible si las ventas seleccionadas son con descuento. Si son con comisión o ventas normales
     * no hace falta validar el saldo ya que puede hacer pago parcial
     */

    if (disabledSelectSinRecargo && montoBilletera < mTotal)
      dataReturn =
        "El total supera el monto en billetera, si las ventas seleccionadas cuentan con descuentos por plazo no puedes realizar pagos parciales.";
    return dataReturn;
  };
  const updateBilletera = () => {
    setupdateResumen(true);
  };

  useEffect(() => {
    if (selection.length === 0) {
      setForm([]);
    }
  }, [selection]);

  const generarDevolucion = async (venta, tipo) => {
    setConComision(0);
    localStorage.removeItem("conComision");
    handleGetDetalleFactura(venta, "devolucion");
    let exist = suggestions.find((x) => x.idVenta === venta);
    if (exist) {
      if (exist.comision) {
        setConComision(exist.comision);
        localStorage.setItem("conComision", 1);
      }
    }
    if (modalDevolucion) setVentaSelected(null);
    setModalDevolucion(!modalDevolucion);
  };
  async function toggleModalDetalleAnular(venta) {
    generarDevolucion(venta);
  }

  const getDataPdf = async (id) => {
    setOpenBackDrop(true);
    try {
      getVenta(id).then((res) => {
        let newData = articlesPDF.getArticlesPDF(
          res.data.data,
          configGeneral,
          "A4",
          false,
          null,
        );
        setDataPdfFetched(newData);
        setOpenBackDrop(false);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenModalPdf = (id) => {
    setOpenBackDrop(true);
    getDataPdf(id);
    setOpenBackDrop(false);
  };

  const isCuentaCorriente =
    ventaSelected &&
    ventaSelected.pagos_cliente &&
    ventaSelected.pagos_cliente.some(
      (x) => x.medio_pago && x.medio_pago.nombre === "Cuenta Corriente",
    )
      ? true
      : false;

  const hasMultipleMedioPago = () => {
    const tipoDevolucion = ventaSelected.pagos_cliente.some(
      (x) => x.tipo_movimiento && x.tipo_movimiento.nombre === "Devolución",
    );
    const esMultiple = ventaSelected.pagos_cliente.length > 1;
    if (tipoDevolucion || esMultiple) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (urlPdf) {
      setOpenModalComprobanteWhatsapp(true);
    } else if (pdfDataFetched) {
      getPdfBlob("ventaA4", pdfDataFetched, configGeneral).then((res) => {
        if (res) {
          setUrlPdf(res.secure_url);
        }
      });
    }
  }, [urlPdf, pdfDataFetched]);

  useEffect(() => {
    ventaSelected &&
      ventaSelected.tipo_comprobante !== "FCEM A" &&
      setValueFcemA(null);
  }, [ventaSelected]);

  const getDataFacturaAgrupada = async (venta) => {
    if (venta.is_agrupada) {
      setOpenBackDrop(true);
      try {
        const response = await request({
          method: "GET",
          url: getVentasInFactAgrupada(venta.idVenta),
        });
        if (response.status === 200) {
          setVentasAgrupadas(response.data);
          setModalAnular(true);
          setVentaSelected(venta);
          setOpenBackDrop(false);
        } else {
          setOpenBackDrop(false);
          errorNotification(
            "Error al obtener las ventas de la factura agrupada. Por favor reintente",
          );
        }
      } catch (error) {
        console.error(error);
        setOpenBackDrop(false);
        errorNotification(
          "Error al obtener las ventas de la factura agrupada. Por favor reintente",
        );
      }
    } else {
      setModalAnular(true);
      setVentaSelected(venta);
    }
  };

  return cliente ? (
    isPago ? (
      <>
        <PagoFacturasAdeudadas
          setIsPago={setIsPago}
          cliente={cliente}
          selectionList={{ selection, setSelection }}
          setMontos={{ newMontos, setNewMontos }}
          formPost={{ form, setForm }}
          tipo={{ disabledSelectSinRecargo, disabledSelectRecargo }}
          updatebilletera={updatebilletera}
          getFacturasAdeudadas={getFacturasAdeudadas}
          updateResumen={() => setupdateResumen(true)}
          newMontos={newMontos}
          dataEmpleado={{ useEmpleado, useQueryEmpleado }}
          dataCargaSaldo={{
            openModaCargarSaldo,
            updateBilletera,
            setOpenModalCargarSaldo,
            setPrintComprobante,
          }}
        />
        {preview && (
          <Showmodal
            tipo={tipoImpresion}
            preview={preview}
            toggle={toggle}
            data={ventaPdf}
          />
        )}
      </>
    ) : (
      <Container maxWidth={false} className="main-content-container  px-4">
        <Grid container className="page-columns pl-0 pb-4 pt-2">
          <Grid item xs={10} style={{ marginLeft: -25 }}>
            <PageTitle
              title={
                cliente && (
                  <React.Fragment>
                    {cliente.razon_social.toUpperCase()}
                    {cliente.comision ? (
                      <TooltipComision />
                    ) : cliente.recargo ? (
                      <TooltipDcto />
                    ) : null}
                  </React.Fragment>
                )
              }
              subtitle="Comprobantes Pendientes"
              className="text-center  text-lg-left page-header py-2"
            />
          </Grid>

          <Grid item xs={2} className={"d-flex justify-content-end my-auto"}>
            <GoBackCliente history={props.history} backToCliente={true} />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mb-2">
          <Grid item xs={12} md={9}>
            <ResumenDeuda
              montoBilletera={(monto) => setMontoBilletera(monto)}
              updateCliente={(value) => {
                value &&
                  setCliente({
                    ...cliente,
                    monto_billetera: parseFloat(value.monto_billetera),
                  });
              }}
              update={updateResumen}
              updatebilletera={() => {
                setUpdatebilletera(!updatebilletera);
              }}
            />
            <BotonesResumenDeuda
              idCliente={idCliente}
              props={props}
              setOpenModalCargarSaldo={() => setOpenModalCargarSaldo(true)}
            />
          </Grid>
          {/* <Grid item xs={0} sm={2} /> */}
          <Grid item xs={12} md={3}>
            <EncabezadoPagoFacturas
              selectionList={{ selection, setSelection }}
              newMontos={newMontos}
            />

            <Button
              style={{
                borderRadius: 4,
                marginTop: 5,
                paddingBottom: 7,
                paddingTop: 7,
              }}
              color="primary"
              variant="contained"
              fullWidth
              disabled={selection.length === 0 ? true : false}
              className="mb-2 font-weight-bold"
              size="lg"
              onClick={() => setIsPago(true)}
            >
              CONCILIAR FACTURAS
            </Button>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <AntTabs
              backgroundColor="white"
              value={valueTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="disabled tabs example"
            >
              <AntTab
                label="Comprobantes Pendientes"
                style={{ outline: "none" }}
              />
              <AntTab label="Remitos" style={{ outline: "none" }} />
            </AntTabs>

            {valueTab === 0 && (
              <Card className="p-3 mb-3">
                <TablaFacturas
                  disabledRecargo={{
                    disabledSelectRecargo,
                    setDisabledSelectRecargo,
                  }}
                  disabledSinRecargo={{
                    disabledSelectSinRecargo,
                    setDisabledSelectSinRecargo,
                  }}
                  items={formatFacturasImpagas(suggestions, classes)}
                  formPost={{ form, setForm }}
                  selectionList={{ selection, setSelection }}
                  columns={columns}
                  loading={loading}
                  printPdf={printPdf}
                  suggestions={suggestions}
                  generarPdfVenta={generarPdfVenta}
                  anularVenta={(id) => {
                    getDataFacturaAgrupada(id);
                  }}
                  devolucionVenta={generarDevolucion}
                  verDetalle={(id) => {
                    setModalDetalle(true);
                    getVentaPorId(id).then((res) => {
                      let newDet = getNewDataDetalle(res.detalles);
                      let newRes = {
                        ...res.venta,
                        fechaHora: res.venta.fechaHoraVenta,
                        detalle: newDet,
                      };
                      setVentaSelected(newRes);
                    });
                  }}
                  ventas={suggestions}
                  openModalDetalle={setComprobanteDetalle}
                  description={{ descripcion, setDescripcion }}
                  setMontos={{ newMontos, setNewMontos }}
                  cliente={{ cliente, idCliente }}
                  getFacturasAdeudadas={getFacturasAdeudadas}
                  pagination={pagination}
                  setPagination={setPagination}
                  handleOpenModalPdf={handleOpenModalPdf}
                  handleModalFechaVencimiento={handleModalFechaVencimiento}
                  dataErrorAFIP={dataErrorAFIP}
                  setDataErrorAFIP={setDataErrorAFIP}
                  modalPdf={modalPdf}
                  setModalPdf={setModalPdf}
                />
                {suggestions.length > 20 && (
                  <EncabezadoPagoFacturas
                    selectionList={{ selection, setSelection }}
                    newMontos={newMontos}
                  />
                )}
              </Card>
            )}
            {valueTab === 1 && (
              <InformeRemitos props={props} clienteCCC={cliente} />
            )}
          </Grid>
        </Grid>

        {modalDetalle && ventaSelected ? (
          <ModalDetalle
            open={modalDetalle}
            selected={ventaSelected}
            toggle={() => toggleModalDetalle()}
          />
        ) : null}
        {openBackDrop && (
          <Backdrop
            className={classes.backdrop}
            open={openBackDrop}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {preview && (
          <Showmodal
            tipo={tipoImpresion}
            preview={preview}
            toggle={toggle}
            data={ventaPdf}
          />
        )}
        {openModalComprobanteWhatsapp && (
          <ModalWhatsApp
            open={openModalComprobanteWhatsapp}
            close={() => {
              setDataPdfFetched(null);
              setUrlPdf(null);
              setOpenModalComprobanteWhatsapp(false);
            }}
            telefono={cliente ? cliente.telefonoContacto : ""}
            nombre={cliente ? cliente.razon_social : ""}
            mensajePdf={`¡Hola${
              cliente ? ` ${cliente.razon_social}!` : "!"
            } Te envíamos a continuación el comprobante de tu compra en formato PDF. Por favor Hace click en el siguiente link para visualizarlo: `}
            urlPdf={urlPdf}
          />
        )}

        {ventaSelected && modalDevolucion ? (
          <ModalNotaCredito
            open={modalDevolucion}
            selected={ventaSelected.venta}
            toggle={() => toggleModalDetalleAnular()}
            getVentas={getFacturasAdeudadas}
            conComision={conComision}
            isInforme={false} //Informa que no esta llamando al modal desde el informe de ventas
            setOpenBackdrop={setOpenBackDrop}
            setDataErrorAFIP={setDataErrorAFIP}
            closeModal={setModalDevolucion}
            setVentaSelected={setVentaSelected}
            modalPdf={modalPdf}
            setModalPdf={setModalPdf}
          />
        ) : null}
        {ventaSelected && modalAnular ? (
          ventaSelected.is_agrupada ? (
            <ModalAnulacionFactAgrupada
              open={modalAnular}
              handleClose={() => {
                setModalAnular(!modalAnular);
                setVentaSelected(null);
                setValueFcemA(null);
                setIdMedioPago(null);
              }}
              ventaSelected={ventaSelected}
              ventasAgrupadas={ventasAgrupadas}
              dataObservaciones={{ observaciones, setObservaciones }}
              getVentas={getFacturasAdeudadas}
              setDataErrorAFIP={setDataErrorAFIP}
            />
          ) : (
            <ComponenteProtegido
              claveMaestraActiva={configGeneral.clave_maestra_activa}
              onClose={() => {
                setModalAnular(false);
                setValueFcemA("N");
                setVentaSelected(null);
              }}
            >
              <ModalConfirm
                open={modalAnular}
                title="Confirmar anulación de venta"
                body={
                  <ModalContentAnulacion
                    ventaSelected={ventaSelected}
                    setIdMedioPago={setIdMedioPago}
                    cliente={cliente}
                    showMedioPago={false}
                    valueFcemA={valueFcemA}
                    setValueFcemA={setValueFcemA}
                    dataObservaciones={{ observaciones, setObservaciones }}
                  />
                }
                openBackdrop={openBackDrop}
                disabled={
                  isCuentaCorriente &&
                  !hasMultipleMedioPago() &&
                  !cliente.cuenta_corriente
                }
                buttons={{ cancel: "Cancelar", submit: "Aceptar" }}
                handleClose={() => {
                  setModalAnular(!modalAnular);
                  setValueFcemA("N");
                  setVentaSelected(null);
                }}
                handleSubmit={anularVenta}
              />
            </ComponenteProtegido>
          )
        ) : null}
        {modalFechaVencimiento && (
          <ModalFechaVencimiento
            open={modalFechaVencimiento}
            close={() => setModalFechaVencimiento(false)}
            venta={ventaSelected}
            getFacturasAdeudadas={getFacturasAdeudadas}
          />
        )}
        {openModaCargarSaldo && (
          <ModalCargaSaldo
            cliente={cliente}
            update={updateBilletera}
            open={openModaCargarSaldo}
            setOpen={() => setOpenModalCargarSaldo(!openModaCargarSaldo)}
            setPrintComprobante={setPrintComprobante}
            dataEmpleado={{ useEmpleado, useQueryEmpleado }}
          />
        )}
      </Container>
    )
  ) : (
    <div style={{ textAlign: "center", height: "100%", marginTop: 200 }}>
      <CircularProgress color="inherit" size={70} />
    </div>
  );
}
