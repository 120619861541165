import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import { parseCurrency } from "../../../../utils/parsers";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "block",
    flexGrow: 1,
    transition: theme.transitions.create(["margin"]),
    margin: "12px 0",
    "&$expanded": {
      margin: "20px 0",
    },
  },
}));

export default function AccordionItem({ n }) {
  const classes = useStyles();
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
        classes={{ content: classes.content }}
      >
        <div>
          <span style={{ fontWeight: "bolder" }}>Fecha de alta: </span>
          {moment(n.nota_credito[0].fecha_alta).format("DD/MM/YY HH:mm")}
        </div>
        <div>
          <span style={{ fontWeight: "bolder" }}>Fecha de facturación: </span>
          {moment(n.nota_credito[0].fecha_facturacion).format("DD/MM/YY HH:mm")}
        </div>
        <div>
          <span style={{ fontWeight: "bolder" }}>Monto: </span>${" "}
          {Number(parseCurrency(n.nota_credito[0].monto_total)).toFixed(2)}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid
            item
            container
            display="flex"
            alignContent="center"
            xs={12}
            sm={6}
          >
            <Typography
              style={{
                fontWeight: "bolder",
                fontSize: "0.9rem",
                paddingRight: "0.3em",
              }}
            >
              Responsable:
            </Typography>{" "}
            {`${n.nota_credito[0].responsable_compra.nombre} ${n.nota_credito[0].responsable_compra.apellido} ` ||
              "- - -"}
          </Grid>
          <Grid
            item
            container
            display="flex"
            alignContent="center"
            xs={12}
            sm={6}
          >
            <Typography
              style={{
                fontWeight: "bolder",
                fontSize: "0.9rem",
                paddingRight: "0.3em",
              }}
            >
              Tipo de comprobante:
            </Typography>{" "}
            Nota de crédito de descuento
          </Grid>

          <Grid
            item
            container
            display="flex"
            alignContent="center"
            xs={12}
            sm={6}
          >
            <Typography
              style={{
                fontWeight: "bolder",
                fontSize: "0.9rem",
                paddingRight: "0.3em",
              }}
            >
              Estado Nota de Crédito:
            </Typography>
            {n.nota_credito[0].estado_nc.nombre}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
