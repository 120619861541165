import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";

import { sanitizeDate } from "../utils";
import { useSupportData } from "../../componentes/FormularioMovimiento/hooks";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import Period from "../../../../components/ait-reusable/PeriodFilter/Period";
import { useSearchTodosEmpleados } from "../../../../customHooks/useSearchEmpleado";
import { useSelector } from "react-redux";

const periodos = [
  { name: "Historico", value: "all" },
  { name: "Hoy", value: "today" },
  { name: "Ayer", value: "yesterday" },
  { name: "Esta Semana", value: "thisWeek" },
  { name: "Este Mes", value: "thisMonth" },
  { name: "Mes Anterior", value: "lastMonth" },
  { name: "Este Año", value: "thisYear" },
  { name: "Personalizado", value: "custom" },
];

export default function Filtro({ filtro, handleChange }) {
  const { empleado } = useSelector((state) => state.loginReducer);
  const isVendedor =
    empleado && empleado.rol && empleado.rol.nombre === "Vendedor";

  const [errors, setErrors] = useState({});
  const [period, setPeriod] = useState(filtro.periodo.tipo || "today");
  const [dates, setDates] = useState(filtro.periodo);
  const [description, setDescription] = useState("");
  const [categorias, setCategorias] = useState([]);
  const [responsables, setResponsables] = useState([]);

  const { useEmpleado } = useSearchTodosEmpleados();
  const [listadoCategorias, _] = useSupportData();

  const sendRequest = () => {
    let nuevoFiltro = { ...filtro };
    nuevoFiltro = {
      ...filtro,
      periodo: {
        tipo: period,
        ...sanitizeDate(dates),
      },
      categorias,
      descripcion: description,
      responsables,
    };
    handleChange(nuevoFiltro);
    localStorage.setItem(
      "filtroCuentaMovimientos",
      JSON.stringify(nuevoFiltro),
    );
  };

  return (
    <Box display={"flex"} gridGap={4}>
      <Period
        fullWidth
        period={period}
        setPeriod={setPeriod}
        dates={dates}
        noButton={true}
        setDates={setDates}
        optionsPeriod={periodos}
        sizeGrid={3}
        errors={errors}
        setErrors={setErrors}
        disablePeriodFilter={isVendedor}
      >
        <Grid item xs={12} lg={3}>
          <Autocomplete
            fullWidth
            multiple
            size="small"
            options={listadoCategorias.data}
            getOptionLabel={(option) => option.nombre}
            getOptionSelected={(option, value) => option.id === value.id}
            value={categorias}
            onChange={(event, categorias, reason) => setCategorias(categorias)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                style={{ minWidth: "200px" }}
                label="Seleccionar categoria"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Autocomplete
            fullWidth
            multiple
            size="small"
            options={useEmpleado.empleados}
            getOptionLabel={(option) => `${option.apellido}, ${option.nombre}`}
            getOptionSelected={(option, value) =>
              option.idEmpleado === value.idEmpleado
            }
            value={responsables}
            onChange={(event, responsable, reason) =>
              setResponsables(responsable)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                style={{ minWidth: "200px" }}
                label="Seleccionar responsable"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextField
            type={"text"}
            variant="outlined"
            fullWidth
            size="small"
            label="Buscar por descripción"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={sendRequest}
            style={{ outline: "none" }}
          >
            CONSULTAR
          </Button>
        </Grid>
      </Period>
    </Box>
  );
}
