import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import ButtonAceptar from "../../../components/ait-reusable/Button/ButtonAceptar";
import { getUbicaciones } from "./utils";

export default function ModalUbicacion({
  open,
  toggle,
  deposito,
  dataUbicacionArticulo,
  indexubicacionArticulo,
  ...props
}) {
  const [zonas, setzonas] = useState([]);
  const [estantes, setestantes] = useState([]);
  const [filas, setfilas] = useState([]);
  const [ubicacion, setubicacion] = useState({
    idDeposito: null,
    idZona: null,
    idEstante: null,
    idUbicacion: null,
  });
  /**cuando va a editar la ubicación del artículo, recibe todo el objeto de ubicaciones
   * que antes fue enviado desde este modal, y luego lo setea, y va haciendo las peticiones según
   * el id correspondiente.
   */
  React.useEffect(() => {
    if (dataUbicacionArticulo.length > 0) {
      let box = dataUbicacionArticulo[0];
      setubicacion(box);
      getUbicaciones(box.idDeposito.idUbicacion).then((res) => setzonas(res));
      getUbicaciones(box.idZona.idUbicacion).then((res) => setestantes(res));
      getUbicaciones(box.idEstante.idUbicacion).then((res) => setfilas(res));
    }
  }, [dataUbicacionArticulo[0]]);
  const handleChange = (e) => {
    /**guardamos el objeto para no tener problemas cuando se quiere mostrar el nombre de la ubicacion */
    setubicacion({
      ...ubicacion,
      [e.target.name]:
        e.target.name === "idDeposito"
          ? actionDeposito(e.target.value)
          : e.target.name === "idZona"
            ? actionZona(e.target.value)
            : e.target.name === "idEstante"
              ? actionEstante(e.target.value)
              : actionFila(e.target.value),
    });
  };
  const actionDeposito = (e) => {
    getUbicaciones(e).then((res) => setzonas(res));
    return deposito.find((x) => parseInt(x.idUbicacion) === parseInt(e) && x);
  };
  const actionZona = (e) => {
    getUbicaciones(e).then((res) => setestantes(res));
    return zonas.find((x) => parseInt(x.idUbicacion) === parseInt(e) && x);
  };
  const actionEstante = (e) => {
    getUbicaciones(e).then((res) => setfilas(res));
    return estantes.find((x) => parseInt(x.idUbicacion) === parseInt(e) && x);
  };
  const actionFila = (e) => {
    return filas.find((x) => parseInt(x.idUbicacion) === parseInt(e) && x);
  };
  /**el bontón de aceptar del fomrulario estará deshabilitado si no están todos
   * los campos completos.*/
  const handleDisabled = () => {
    let disabled = false;
    Object.values(ubicacion).map((value) => {
      if (value === null || value === "" || value === undefined) {
        disabled = true;
      }
    });
    return disabled;
  };
  return (
    <div>
      <Dialog open={open} onClose={toggle}>
        <DialogTitle>Elige la ubicación para el nuevo artículo</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <label className="fontBold">Depósito:</label>
              <Box>
                <TextField
                  name="idDeposito"
                  select
                  size="small"
                  fullWidth
                  label="Elegir deposito"
                  variant="outlined"
                  onChange={handleChange}
                  value={
                    ubicacion.idDeposito && ubicacion.idDeposito.idUbicacion
                  }
                  autoFocus
                >
                  <MenuItem value="">Elegir depósito ...</MenuItem>
                  {deposito.length > 0 &&
                    deposito.map((d, index) => (
                      <MenuItem value={d.idUbicacion}>{d.descripcion}</MenuItem>
                    ))}
                </TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <label className="fontBold">Zona:</label>
              <TextField
                name="idZona"
                label="Elegir zona"
                variant="outlined"
                fullWidth
                select
                size="small"
                onChange={handleChange}
                value={ubicacion.idZona && ubicacion.idZona.idUbicacion}
                disabled={Boolean(!ubicacion.idDeposito)}
              >
                <MenuItem value="">Elegir zona ...</MenuItem>
                {zonas.map((zona, index) => (
                  <MenuItem value={zona.idUbicacion}>
                    {zona.descripcion}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <label className="fontBold">Cuerpo:</label>
              <TextField
                name="idEstante"
                disabled={Boolean(!ubicacion.idZona)}
                size="small"
                fullWidth
                select
                variant="outlined"
                label="Elegir cuerpo"
                onChange={handleChange}
                value={ubicacion.idEstante && ubicacion.idEstante.idUbicacion}
                id="selectEstante"
              >
                <MenuItem value="">Elegir cuerpo ...</MenuItem>
                {estantes.map((estante, index) => (
                  <MenuItem value={estante.idUbicacion}>
                    {estante.descripcion}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} md={6}>
              <label className="fontBold">Fila:</label>
              <TextField
                name="idUbicacion"
                disabled={Boolean(!ubicacion.idEstante)}
                label="Elegir fila"
                variant="outlined"
                select
                size="small"
                fullWidth
                onChange={handleChange}
                value={
                  ubicacion.idUbicacion && ubicacion.idUbicacion.idUbicacion
                }
              >
                <MenuItem value="">Elegir fila ...</MenuItem>
                {filas.map((fila, index) => (
                  <MenuItem value={fila.idUbicacion}>
                    {fila.descripcion}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item xs={12} className="d-flex justify-content-end mt-4">
            <ButtonAceptar
              message={"ACEPTAR"}
              click={() => props.handleSubmitUbicacion(ubicacion)}
              disabled={handleDisabled()}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
