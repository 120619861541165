import React from "react";

const useArticlesToPDF = () => {
  /**
   * Funcion que se encarga de dividir las descripciones de los articulos y retornar un array con las nuevas descripciones y data de detalles.
   * @param {Object} data data de comprobante
   * @param {Object} config data de config general
   * @param {String} tipo Tipo de comprobante
   * @param {Boolean} isRemito Indica si es un remito
   * @returns Object: Data de todo el comprobante
   */
  const getArticlesPDF = (data, config, tipo, isRemito, comprobImprimir) => {
    let isTicket = tipo.includes("Ticket") ? true : false;
    const tipoCompr = data.venta.has_factura ? "Factura" : "CI";
    const tieneFactura = data.factura ? true : false;
    const tipoFactura =
      tipoCompr === "Factura" && tieneFactura
        ? data.factura.idTipoFactura.nombre
        : "";
    let isFactAgrupada =
      data.detalles_ventas_agrupadas &&
      data.detalles_ventas_agrupadas.length > 0
        ? true
        : false;

    if (!isTicket) {
      const copyData = data;

      /**
       * 1- Si es una factura:
       *   a- Si es agrupada y es remito: Se toma data.venta.detalle_ventas
       *   b- Sino:
       *     b.1: Si tiene comprobanteAImprimir y es Factura se toma data.receipt.entries
       *     b.2: Si tiene comprobanteAImprimir y es Venta se toma data.venta.detalle_ventas
       *     b.3: Si no tiene comprobanteAImprimir se toma data.receipt.entries
       * 2- Si es un comprobante interno: Se toma data.venta.detalle_ventas
       */
      const isReceiptsDetalles =
        tipoCompr === "Factura"
          ? isFactAgrupada && isRemito
            ? false
            : comprobImprimir
              ? comprobImprimir === "Factura"
                ? true
                : false
              : true
          : false;

      const copyArt = isReceiptsDetalles
        ? data.receipt.entries
        : data.venta.detalle_ventas.slice();
      const newArt = [];

      copyArt.forEach((d, idx) => {
        /**
         * 1- Si tiene comprobanteAImprimir:
         *   a. Si es factura se toma detalles_ventas_agrupadas sino se toma detalle_ventas
         * 2- Si no tiene comprobanteAImprimir:
         *   a. Si es factura agrupada y no es remito se toma data.detalles_ventas_agrupadas sino se toma data.venta.detalle_ventas
         */
        let newDetalles = comprobImprimir
          ? comprobImprimir === "Factura"
            ? data.detalles_ventas_agrupadas
            : data.venta.detalle_ventas
          : isFactAgrupada && !isRemito
            ? data.detalles_ventas_agrupadas
            : data.venta.detalle_ventas;

        let cadena = newDetalles[idx].descripcionPersonalizada
          ? newDetalles[idx].descripcionPersonalizada
          : config.ocultar_codigo_pdf
            ? newDetalles[idx].descripcion
            : newDetalles[idx].descripcion_ticket;

        let lengthCadena = cadena.length;
        const ciclos = Math.ceil(lengthCadena / 40);

        for (var i = 0; i < ciclos; i++) {
          let newCadena = cadena.substr(0, 40).trim();
          let nextCant = (lengthCadena - 40) * -1;

          /**Define que datos se tienen que agregar a newLine.
          Existen tres tipos:
          1. Valores para Factura (typeFactura)
          2. Valores para Remito con factura agrupada (typeRemitoAgrupada)
          3. Valores para CI (typeCI)
          */
          const typeLine =
            tipoCompr === "Factura"
              ? isFactAgrupada && isRemito
                ? "typeRemitoAgrupada"
                : comprobImprimir
                  ? comprobImprimir === "Factura"
                    ? "typeFactura"
                    : "typeCI"
                  : "typeFactura"
              : "typeCI";
          let newLine =
            typeLine === "typeFactura"
              ? {
                  description: newCadena,
                  id: d.id,
                  quantity: i > 0 ? "" : d.quantity,
                  unit_price:
                    i > 0
                      ? ""
                      : tipoFactura === "B"
                        ? getPrecioUnitario(isFactAgrupada, data, idx)
                        : d.unit_price,
                  vat: d.vat,
                  noLine: i === ciclos - 1 ? false : true,
                  priceTotal:
                    i > 0
                      ? ""
                      : tipoFactura === "A" || tipoFactura === "M"
                        ? getPrecioUnitario(isFactAgrupada, data, idx) *
                          d.quantity
                        : "",
                  descripcionPersonalizada: d.descripcionPersonalizada || null,
                }
              : typeLine === "typeCI"
                ? {
                    cantidad: i > 0 ? "" : d.cantidad,
                    cantidad_real: d.cantidad_real,
                    newDescripcion: newCadena,
                    descripcion: d.descripcion,
                    descripcion_ticket: d.descripcion_ticket,
                    idDetalleVenta: d.idDetalleVenta,
                    idRepuestoSucursal: d.idRepuestoSucursal,
                    idVenta: d.idVenta,
                    repuestoSucursal: d.repuestoSucursal,
                    subtotal: i > 0 ? "" : d.subtotal,
                    tipo_iva: d.tipo_iva,
                    noLine: i === ciclos - 1 ? false : true,
                    nombre_lista_precios: i > 0 ? "" : d.nombre_lista_precios,
                    descripcionPersonalizada:
                      d.descripcionPersonalizada || null,
                  }
                : {
                    description: newCadena,
                    id: d.id,
                    quantity: i > 0 ? "" : d.cantidad,
                    unit_price:
                      i > 0
                        ? ""
                        : Number((Number(d.subtotal) / d.cantidad).toFixed(2)),
                    vat: "",
                    noLine: i === ciclos - 1 ? false : true,
                    descripcionPersonalizada:
                      d.descripcionPersonalizada || null,
                  };

          newArt.push(newLine);

          cadena = cadena.substr(nextCant).trim();
          lengthCadena = lengthCadena - 40;
        }
      });
      if (tipoCompr === "Factura") {
        if (comprobImprimir) {
          if (comprobImprimir === "Factura") {
            copyData.receipt.entries = newArt;
          } else {
            copyData.venta.detalle_ventas = newArt;
          }
        } else {
          copyData.receipt.entries = newArt;
        }
      } else {
        copyData.venta.detalle_ventas = newArt;
      }

      console.log("1");
      return copyData;
    } else {
      if (tipoCompr === "Factura") {
        let copyArt = data.receipt.entries.slice();
        copyArt.forEach((d, idx) => {
          d.unit_price =
            tipoFactura === "B"
              ? getPrecioUnitario(isFactAgrupada, data, idx)
              : d.unit_price;
          d.priceTotal =
            tipoFactura === "A" || tipoFactura === "M"
              ? getPrecioUnitario(isFactAgrupada, data, idx) * d.quantity
              : "";
        });
        data.receipt.entries = copyArt;
        console.log("2");
        return data;
      } else {
        console.log("3");
        return data;
      }
    }
  };

  const getPrecioUnitario = (isFactAgrupada, data, idx) => {
    let detalle_precio_unitario = isFactAgrupada
      ? data.detalles_ventas_agrupadas
      : data.venta.detalle_ventas;
    let precioUnitario = Number(
      (
        detalle_precio_unitario[idx].subtotal /
        detalle_precio_unitario[idx].cantidad
      ).toFixed(2),
    );

    return precioUnitario;
  };
  return { articlesPDF: { getArticlesPDF } };
};

export default useArticlesToPDF;
