import React from "react";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import { Container, Tabs, Tab, Box, Divider } from "@material-ui/core";
import { MovimientosMedios } from "../MovimientosMedios";
import { useModulosContext } from "../../../context/ModulosContext";
import { useParams } from "react-router-dom";
import { MODULOS } from "../../../constantes/modulos";
import Ventas from "../Ventas";
import Remitos from "../Remitos";
import IndexInforme from "../NotaDeCredito/IndexInforme";
import Presupuestos from "../Presupuestos";

export default function Combinacion(props) {
  const { tab } = useParams();
  const { tieneModuloActivo } = useModulosContext();
  const { activo: moduloRemitosActivo } = tieneModuloActivo(MODULOS.REMITO);
  const { activo: moduloPresupuestosActivo } = tieneModuloActivo(
    MODULOS.PRESUPUESTO,
  );

  let tabs = [
    { value: "ventas-general", label: "Ventas" },
    { value: "presupuestos", label: "Presupuestos" },
    { value: "notas-de-credito", label: "Notas de Crédito" },
    { value: "remitos", label: "Remitos" },
    // { value: "movimientos-medios", label: "Resumen de ingresos" },
  ];

  const handleFormatTabs = () => {
    if (!moduloPresupuestosActivo) {
      tabs = tabs.filter((tab) => tab.value !== "presupuestos");
    }

    if (!moduloRemitosActivo) {
      tabs = tabs.filter((tab) => tab.value !== "remitos");
    }

    if (!tabs.map((e) => e.value).includes(tab)) {
      props.history.replace("/error-404/");
      return null;
    }

    return tabs.map((tab) => (
      <Tab key={tab.value} label={tab.label} value={tab.value} />
    ));
  };

  const handleOnChangeTab = (event, value) => {
    props.history.push(`/informe/${value}`);
  };

  return (
    <Container fluid className="main-content-container px-4" maxWidth={false}>
      <PageHeader
        title={tabs.filter((t) => t.value === tab)[0].label}
        history={props.history}
        url={"/informes/"}
      />

      <Tabs
        indicatorColor="primary"
        value={tab}
        textColor="primary"
        onChange={handleOnChangeTab}
        aria-label="simple tabs example"
      >
        {handleFormatTabs()}
      </Tabs>
      <Box pb={1}>
        <Divider />
      </Box>
      {tab === "ventas-general" && <Ventas />}
      {tab === "presupuestos" && <Presupuestos props={props} />}
      {tab === "notas-de-credito" && <IndexInforme />}
      {tab === "remitos" && <Remitos props={props} />}
      {tab === "movimientos-medios" && <MovimientosMedios props={props} />}
    </Container>
  );
}
