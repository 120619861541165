import { Box, Tab, Tabs } from "@material-ui/core";
import React from "react";
import Acciones from "./Acciones";
import ListaMovimientos from "../../componentes/ListaMovimientos";
import { obtenerInitialStateFiltroCuentaMovimientos } from "../utils";
import Filtro from "./Filtro";
import TotalesMovimientosCuenta from "../../componentes/TotalesMovimientosCuenta";
import { DatosSoporteMovimientosProvider } from "../../context/DatosSoporte";
import { useSelector } from "react-redux";

const TABS = [
  { value: "movimientos", label: "Movimientos" },
  { value: "graficos", label: "Gráficos" },
];

export default function VistaMovimientos({ cuenta }) {
  const { empleado } = useSelector((state) => state.loginReducer);
  const isVendedor =
    empleado && empleado.rol && empleado.rol.nombre === "Vendedor";

  const [tab, setTab] = React.useState("movimientos");
  const [filtro, setFiltro] = React.useState(
    obtenerInitialStateFiltroCuentaMovimientos(isVendedor),
  );

  const handleChangeFiltro = (filtroActualizado) => {
    setFiltro((filtro) => ({ ...filtro, ...filtroActualizado }));
  };

  const extra_filtro = {
    ...filtro.periodo,
    categoria_ids: filtro.categorias.map((c) => c.id),
    descripcion: filtro.descripcion.split(" "),
    responsable_ids: filtro.responsables.map((r) => r.idEmpleado),
  };
  return (
    <Box>
      <DatosSoporteMovimientosProvider>
        <Box my={1}>
          <Filtro filtro={filtro} handleChange={handleChangeFiltro} />
        </Box>

        <Box
          my={1}
          display={"flex"}
          justifyContent={"flex-end"}
          style={{ paddingBottom: "10px", paddingTop: "10px" }}
        >
          <Acciones cuenta={cuenta} />
        </Box>

        <Box my={1}>
          <TotalesMovimientosCuenta
            cuenta={cuenta}
            extra_params={extra_filtro}
          />
        </Box>
        <ListaMovimientos cuenta={cuenta} extra_filtro={extra_filtro} />
      </DatosSoporteMovimientosProvider>
    </Box>
  );
}
