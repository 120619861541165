import React from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { pagoDeCompras } from "../../../../requests/urls";

export const firstRelevantData = (pago) => [
  {
    title: "Fecha de pago real:",
    subtitle: `${moment(pago.fecha_real_pago).format("DD/MM/YY HH:mm")}`,
  },
  {
    title: "Fecha de carga de pago:",
    subtitle: `${moment(pago.fecha_hora_pago).format("DD/MM/YY")} ${moment(
      pago.fecha_hora_pago,
    ).format("HH:mm")}`,
  },
  { title: "Orden del Pago:", subtitle: pago.orden_pago },
  // {
  //   title: "Monto del Pago:",
  //   subtitle: `$${parseFloat(pago.monto_pago).toLocaleString("es-AR")}`,
  // },
  {
    title: "Retención",
    subtitle: pago.retencion_pago_compra.length
      ? `${
          pago.retencion_pago_compra[0].monto_retencion
            ? `$ ${pago.retencion_pago_compra[0].monto_retencion}`
            : "---"
        }`
      : "---",
  },
];

export const secondRelevantData = (pago) => [
  {
    title: "Descuento:",
    subtitle: pago.descuento ? `${pago.descuento * 100} %` : "---",
  },
  {
    title: "Proveedor:",
    subtitle: pago.proveedor.razonSocial,
  },
  {
    title: "Responsable:",
    subtitle: `${pago.responsable ? pago.responsable.nombre : ""} ${
      pago.responsable ? pago.responsable.apellido : ""
    }`,
  },
  {
    title: "Observación:",
    subtitle: pago.observacion ? pago.observacion : "",
  },
];

export const formatCompras = (compras, pago, notasCreditoPagoSinDesc) => {
  const pagoDeCompras = compras.map((compra) => ({
    fechaFacturacion: `${moment(compra.compra[0].fecha_facturacion).format(
      "DD/MM/YY",
    )}`,
    tipoComprobante: compra.compra[0].tipo_comprobante.nombre,
    nCompras: compra.compra[0].formatted_number,
    pagado: `$${parseFloat(compra.monto_impactado).toLocaleString("es-AR")}`,
    totalCompras: compra.compra[0].nc
      ? compra.compra[0].nc.saldo
      : `$${parseFloat(compra.compra[0].monto_total).toLocaleString("es-AR")}`,
  }));
  if (pago.ncs_de_pago.length) {
    const notasDeCredito = notasCreditoPagoSinDesc.map((n) => ({
      fechaFacturacion: moment(n.nota_credito[0].fecha_facturacion).format(
        "DD/MM/YY",
      ),
      tipoComprobante: "Nota de Crédito",
      nCompras: n.nota_credito[0].formatted_number,
      totalCompras: (
        <Typography color="error" style={{ fontSize: "0.875rem" }}>
          {" "}
          - ${parseFloat(n.nota_credito[0].monto_total).toLocaleString("es-AR")}
        </Typography>
      ),
    }));

    return pagoDeCompras.concat(notasDeCredito);
  } else {
    return pagoDeCompras;
  }
};

export const firstRelevantDataNc = (pago) => [
  {
    title: "Fecha de alta:",
    subtitle: `${moment(pago[0].nota_credito[0].fecha_real_pago).format(
      "DD/MM/YY HH:mm",
    )}`,
  },
  {
    title: "Fecha de facturación:",
    subtitle: `${moment(pago[0].nota_credito[0].fecha_facturacion).format(
      "DD/MM/YY",
    )}`,
  },
  {
    title: "Nro.:",
    subtitle: pago[0].nota_credito[0].formatted_number,
  },
];

export const secondRelevantDataNc = (pago) => [
  {
    title: "Estado:",
    subtitle: pago[0].nota_credito[0].estado_nc
      ? pago[0].nota_credito[0].estado_nc.nombre
      : "---",
  },
  {
    title: "Fecha Venc.:",
    subtitle: moment(pago[0].nota_credito[0].fecha_vencimiento).format(
      "DD/MM/YY",
    ),
  },

  {
    title: "Total:",
    subtitle: `- ${parseFloat(
      pago[0].nota_credito[0].monto_total,
    ).toLocaleString("es-AR")}`,
  },
];
