import React, { useState, useEffect } from "react";
import ModalReutilizable from "../../../components/ait-reusable/Modal/index";
import ContentModal from "./ContentModal";
import ButtonAceptar from "../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../components/ait-reusable/Button/ButtonCancelar";
import request from "../../../requests/request";
import {
  createClienteSucursal,
  getProvinciaSucursalApi,
  deleteClient,
  updateClienteSucursal,
} from "../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import useClienteDescuentoPlazo from "../../../customHooks/useClienteDescuentoPlazo";
import { updateProvincia } from "../../../Redux/Actions/presupuestoActions";
import { useDispatch } from "react-redux";
import ContentModalInactivo from "../../Clientes/FormularioCliente/ModalClienteInactivo/ContentModal";
import ActionModalInactivo from "../../Clientes/FormularioCliente/ModalClienteInactivo/ActionModal";
import { getDataClienteInactivo, getDataClienteCreate } from "./utils";

export default function ModalClienteMontoExcedido({
  open,
  montoMaximo,
  handleClose,
  confirm,
}) {
  const formRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const { setNewClient } = useClienteDescuentoPlazo();
  const [provinciaDefault, setProvinciaDefault] = useState([]);
  const [clienteInactivo, setClienteInactivo] = useState(null);
  const [infoClienteInactivo, setInfoClienteInactivo] = useState(false);
  const [dataClienteNuevo, setDataClienteNuevo] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getProvinciaSucursal();
  }, []);

  const seleccionCliente = (values) => {
    if (clienteInactivo) {
      editarClienteInactivo(values);
    } else {
      if (!values.cliente) {
        //Se esta registrando un nuevo cliente
        createCliente(values);
      } else {
        //Se selecciono un cliente existente y ya esta guardado en redux
        handleClose();
        confirm();
      }
    }
  };

  const createCliente = async (values) => {
    setLoading(true);

    try {
      const dataClienteCreate = await getDataClienteCreate(
        values,
        provinciaDefault,
      );
      const response = await request({
        method: "POST",
        url: createClienteSucursal,
        data: dataClienteCreate,
      });
      handleResponseCliente(response);
    } catch (error) {
      if (error.message.includes("409")) {
        const clienteActivo = error.response.data.data.activo;
        if (!clienteActivo) {
          setDataClienteNuevo(values);
          setClienteInactivo(error.response.data.data);
          setInfoClienteInactivo(true);
          errorNotification(
            "Ya existe un cliente registrado con esa información y esta inactivo.",
          );
        } else {
          errorNotification(
            "Ya existe un cliente registrado con esa información. " +
              "Por favor, revisar el DNI.",
          );
        }
      } else {
        errorNotification(
          "Error de validacion, corrobore los datos ingresados.",
        );
      }
      setLoading(false);
    }
  };

  const handleResponseCliente = async (response) => {
    if (response.status === 201) {
      const clienteRegistrado = response.data.data;
      await setNewClient(clienteRegistrado, true);

      dispatch(
        updateProvincia(
          clienteRegistrado && clienteRegistrado.cliente.provincia
            ? clienteRegistrado.cliente.provincia
            : provinciaDefault
              ? provinciaDefault.id
              : null,
        ),
      );
      successNotification("Cliente registrado exitosamente.");
      setLoading(false);
      handleClose();
      confirm();
    } else {
      errorNotification(
        "Ocurrio un error durante el registro. " + "Verifique informacion.",
      );
      setLoading(false);
    }
  };

  const getProvinciaSucursal = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getProvinciaSucursalApi,
      });
      setProvinciaDefault(response.data.data);
      localStorage.setItem("provinciaDefault", response.data.data.id);
    } catch (error) {
      console.error(error);
      setProvinciaDefault(null);
    }
  };

  const handleCloseClienteInactivo = () => {
    setClienteInactivo(null);
    setInfoClienteInactivo(false);
  };

  const handleEditClienteInactivo = async (edit) => {
    if (edit) {
      setInfoClienteInactivo(false);
    } else {
      setLoading(true);
      try {
        const response = await request({
          method: "DELETE",
          url: deleteClient(clienteInactivo.idClienteDeSucursal),
          params: {
            activo: 1,
          },
        });
        if (response.status === 200) {
          setLoading(false);
          const newCliente = { ...clienteInactivo, activo: true };
          await setNewClient(newCliente, true);

          dispatch(
            updateProvincia(
              newCliente && newCliente.cliente.provincia
                ? newCliente.cliente.provincia
                : provinciaDefault
                  ? provinciaDefault.id
                  : null,
            ),
          );
          successNotification("Cliente dado de alta exitosamente.");
          setInfoClienteInactivo(false);
          setClienteInactivo(null);
          handleClose();
          confirm();
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
        errorNotification("Ocurrió un error al activar el cliente.");
      }
    }
  };

  const editarClienteInactivo = async (values) => {
    setLoading(true);

    try {
      const dataCliente = await getDataClienteInactivo(values, clienteInactivo);
      const response = await request({
        method: "PUT",
        url: updateClienteSucursal(clienteInactivo.idClienteDeSucursal),
        data: dataCliente,
      });
      handleResponseClienteInactivo(response);
    } catch (error) {
      setLoading(false);
      errorNotification("Ocurrió un error al activar el cliente.");
    }
  };

  const handleResponseClienteInactivo = async (response) => {
    if (response.status === 201) {
      setLoading(false);
      successNotification("Cliente dado de alta exitosamente.");
      const newCliente = response.data.data;
      await setNewClient(newCliente, true);

      dispatch(
        updateProvincia(
          newCliente && newCliente.cliente.provincia
            ? newCliente.cliente.provincia
            : provinciaDefault
              ? provinciaDefault.id
              : null,
        ),
      );
      setInfoClienteInactivo(false);
      setClienteInactivo(null);
      setDataClienteNuevo(null);
      handleClose();
      confirm();
    } else {
      setLoading(false);
      errorNotification("Ocurrió un error al activar el cliente.");
    }
  };

  return (
    <ModalReutilizable
      open={open}
      content={
        infoClienteInactivo ? (
          <ContentModalInactivo clienteInactivo={clienteInactivo} />
        ) : (
          <ContentModal
            formRef={formRef}
            onSubmit={seleccionCliente}
            provinciaDefault={provinciaDefault}
            valuesPrevious={dataClienteNuevo}
            clienteInactivo={clienteInactivo}
            montoMaximo={montoMaximo}
          />
        )
      }
      title={"Selección de cliente"}
      subtitle={`El monto de la venta supera los $${Number(
        montoMaximo,
      ).toLocaleString("es-AR")}`}
      handleClose={handleClose}
      openBackdrop={loading}
      actions={
        infoClienteInactivo ? (
          <ActionModalInactivo
            handleCloseClienteInactivo={handleCloseClienteInactivo}
            handleEditClienteInactivo={handleEditClienteInactivo}
          />
        ) : (
          <>
            <ButtonCancelar
              click={handleClose}
              message={"Cancelar"}
              disabled={false}
            />
            <ButtonAceptar
              disabled={loading || !montoMaximo}
              click={() => formRef.current.handleSubmit()}
              message={clienteInactivo ? "Activar y modificar" : "Aceptar"}
            />
          </>
        )
      }
    />
  );
}
