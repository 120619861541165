import Check from "@material-ui/icons/Check";

export const formDefaultValues = ({ impuestos_boxer = [] } = {}) => {
  let impuestos_form = impuestos_boxer.map((impuesto) => {
    return {
      //Se carga el IIBB defecto seleccionado
      activo:
        impuesto.tipo_actualizacion === "Manual" &&
        impuesto.nombre === "Percepción de IIBB",
      id: impuesto.id,
      nombre: impuesto.nombre,
      alicuota: impuesto.alicuota,
      tipo_actualizacion: impuesto.tipo_actualizacion,
      fecha_vencimiento: null,
      fecha_inicio: null,
    };
  });
  return {
    CUIT: "",
    correoElectronico: "",
    domicilio: "",
    idEstadoTributario: 1,
    provincia: null,
    numeroDocumento: "",
    razonSocial: "",
    telefonoContacto: "",
    deudaMaxima: "",
    cliente_meli: false,
    es_comision: false,
    comision: "0",
    id_descuento: " ",
    recargo: [],
    cuenta_corriente: false,
    responsable_cuenta: "",
    esta_grabado_iibb: false,
    dias_deuda: "",
    id_medio_pago: null,
    tipo_cliente: null,
    comprobantePorDefecto: "AMBOS",
    envio_automatico_comprobante_venta: false,
    impuestos: impuestos_form,
  };
};
