import React from "react";
import { Text, View } from "@react-pdf/renderer";
import moment from "moment";
import { styles } from "../../assets/css/styleA4";
import { parseCurrency } from "../../../../../utils/parsers";
import Line_A4, { Line_A4_table, Line_A4_table_white } from "../raya";
export default function BodyTableFactura({
  descripcion,
  cant,
  precio,
  iva,
  subtotal,
  noLine,
  remito,
}) {
  return (
    <View style={{ paddingTop: 5 }}>
      <View style={styles.containerRowBodyTable}>
        <View style={(styles.containerColumn, { width: 300 })}>
          <Text style={styles.textLeftTable}>
            {`${
              descripcion.substr(0, 100).trim() +
              (descripcion.length > 100 ? "..." : "")
            }` || "---"}
          </Text>
        </View>
        <View style={(styles.containerColumn, { width: 30 })}>
          <Text style={styles.textCenterTable}>{cant}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 60 })}>
          {!remito ? (
            <Text style={styles.textCenterTable}>
              {precio !== ""
                ? `$ ${Number(precio).toLocaleString("es-AR")}`
                : ""}
            </Text>
          ) : (
            <Text style={styles.textCenterTable}>---</Text>
          )}
        </View>

        <View style={(styles.containerColumn2, { width: 60 })}>
          {!remito ? (
            <Text style={styles.textRightTable}>
              {iva !== "" ? parseCurrency(iva) : ""}
            </Text>
          ) : (
            <Text style={styles.textRightTable}>---</Text>
          )}
        </View>
        <View style={(styles.containerColumn2, { width: 60 })}>
          {!remito ? (
            <Text style={styles.textRightTable}>
              {subtotal !== ""
                ? `$ ${Number(subtotal).toLocaleString("es-AR")}`
                : ""}
            </Text>
          ) : (
            <Text style={styles.textRightTable}>---</Text>
          )}
        </View>
      </View>
      <View style={{ marginTop: -15 }}>
        {noLine ? <Line_A4_table_white /> : <Line_A4_table />}
      </View>
    </View>
  );
}
