import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  TextField,
  InputAdornment,
  Divider,
  Tooltip,
  MenuItem,
  FormControl,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AlertConComision from "./AlertConComision";
import FormNCMonto from "./FormNCMonto";
import DataVenta from "./DataVenta";
import ReportTable from "../Report/ReportTable";
import ButtonCancelar from "../Button/ButtonCancelar";
import ButtonAceptar from "../Button/ButtonAceptar";
import { comisionMonto, formatDetalle } from "./utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FieldsResponsableMedio } from "./FieldsResponsableMedio";
import AlertMultipleMedio from "./AlertMultipleMedio";
import { useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
}));

const GloboTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
  arrow: { border: "#dadde9", color: "#f5f5f9" },
}))(Tooltip);

export default function CuerpoNC({
  isInforme,
  selected,
  conComision,
  movimiento,
  dataEmpleado,
  errorEmpleado,
  dataNCMonto,
  errorMonto,
  detallesDisponibles,
  handleChangeCantidad,
  enabledAmount,
  montoTotalNC,
  toggle,
  enabledNC,
  handleSubmitNotaCredito,
  loading,
  medioPagoSeleccionado,
  setMedioPagoSeleccionado,
  mediosPago,
  setErrorMedioPago,
  errorMedioPago,
  isPrevisualizacion,
  isCuentaCorriente,
}) {
  const { has_factura, id_desc_total, provincia } = selected;
  const { empleado } = useSelector((state) => state.loginReducer);

  const tieneComision = isInforme
    ? conComision
      ? true
      : false
    : conComision > 0
      ? true
      : false;
  const cliente = isInforme ? selected.cliente : selected.idClienteDeSucursal;
  const fechaAlta = isInforme ? selected.fechaHora : selected.fechaHoraVenta;
  // const hasMultipleMedioPago = selected.pagos_cliente.length > 1;
  const hasMultipleMedioPago = () => {
    // let hasMultipleMedio;
    const tipoDevolucion = selected.pagos_cliente.some(
      (x) => x.tipo_movimiento && x.tipo_movimiento.nombre === "Devolución",
    );
    const esMultiple = selected.pagos_cliente.length > 1;
    if (tipoDevolucion || esMultiple) {
      return true;
    } else {
      return false;
    }
  };

  const actionButton = movimiento.tipoMov ? "CALCULAR" : "VALIDAR";
  const [openTooltip, setOpenTooltip] = useState(false);
  const classes = useStyles();
  const handleClose = () => {
    setOpenTooltip(false);
  };

  const handleOpen = () => {
    if (!enabledNC && movimiento.tipoMov) {
      setOpenTooltip(true);
    }
  };

  const validateResponsable = () => {
    if (dataEmpleado.useEmpleado.empleado) {
      validateMedioPago();
    } else {
      errorEmpleado.setErrorResponsable(true);
    }
  };

  const validateMedioPago = () => {
    if (!medioPagoSeleccionado && hasMultipleMedioPago()) {
      setErrorMedioPago(true);
    } else {
      handleSubmitNotaCredito();
    }
  };

  const handleChangeResponsable = (responsable) => {
    errorEmpleado.setErrorResponsable(false);
    // setLoading(false);
    dataEmpleado.useEmpleado.setEmpleado(responsable);
  };

  useEffect(() => {
    let responsableSelect = null;
    if (empleado.idEmpleado) {
      responsableSelect = empleado || null;
    }

    handleChangeResponsable(responsableSelect);
  }, []);

  return (
    <>
      {tieneComision && (
        <AlertConComision
          text={`La venta generó una comision de $${comisionMonto(
            isInforme,
            selected,
            conComision,
          )} para el cliente, si realiza una devolución se reintegrará el proporcional de la comisión`}
          severity={"warning"}
        />
      )}
      {isCuentaCorriente &&
        !hasMultipleMedioPago() &&
        !selected.cliente.cuenta_corriente && (
          <Box pb={2}>
            <Alert severity={"error"}>
              Para poder anular esta venta es necesario activar la Cuenta
              corriente del cliente
            </Alert>
          </Box>
        )}
      <DataVenta
        cliente={cliente}
        fechaAlta={fechaAlta}
        id_desc_total={id_desc_total}
        provincia={provincia}
        tipoMov={movimiento.tipoMov}
        setTipoMov={movimiento.setTipoMov}
        useEmpleado={dataEmpleado.useEmpleado}
        useQueryEmpleado={dataEmpleado.useQueryEmpleado}
        errorEmpleado={errorEmpleado}
        isPrevisualizacion={false}
        medioPagoSeleccionado={medioPagoSeleccionado}
        setMedioPagoSeleccionado={setMedioPagoSeleccionado}
        mediosPago={mediosPago}
        errorMedioPago={errorMedioPago}
        setErrorMedioPago={setErrorMedioPago}
        dataEmpleado={dataEmpleado}
        isCuentaCorriente={isCuentaCorriente}
        hasMultipleMedioPago={hasMultipleMedioPago}
        handleChangeResponsable={handleChangeResponsable}
        selected={selected}
      />
      {!movimiento.tipoMov && hasMultipleMedioPago() && (
        <Box paddingTop={2}>
          <AlertMultipleMedio
            text={`${
              isCuentaCorriente
                ? "Esta venta posee al menos un pago en Cuenta Corriente por lo que el medio de pago no puede modificarse"
                : "Esta venta posee múltiple medios de pagos, por lo que debera seleccionar un medio de pago."
            }`}
            severity={"info"}
            hasMultipleMedioPago={hasMultipleMedioPago}
            porMontos={movimiento.tipoMov}
            // isCuentaCorriente
          />
        </Box>
      )}

      {!isCuentaCorriente && !hasMultipleMedioPago() && !movimiento.tipoMov && (
        <Box paddingTop={2}>
          <AlertMultipleMedio
            text={`Si no selecciona medio de pago, la ${
              has_factura ? "nota de crédito" : "devolución"
            } se generará por el mismo medio de la venta.`}
            severity={"info"}
            hasMultipleMedioPago={hasMultipleMedioPago}
            porMontos={movimiento.tipoMov}
            isCuentaCorriente={isCuentaCorriente}
          />
        </Box>
      )}

      <Grid container spacing={2} className="pb-3">
        <Grid item xs={12} className="pb-0">
          <Typography
            style={{
              fontWeight: "bolder",
              textAlign: "center",
            }}
            variant="h6"
          >
            {has_factura
              ? `Nota de crédito por ${
                  movimiento.tipoMov ? "artículos" : "montos"
                }`
              : `Devolución por ${movimiento.tipoMov ? "artículos" : "montos"}`}
          </Typography>
        </Grid>

        <Grid item xs={12} className="pt-0">
          {!movimiento.tipoMov ? (
            <FormNCMonto
              dataNCMonto={dataNCMonto.dataNCMonto}
              setDataNCMonto={dataNCMonto.setDataNCMonto}
              errorMonto={errorMonto}
              enabledAmount={enabledAmount}
              medioPagoSeleccionado={medioPagoSeleccionado}
              errorMedioPago={errorMedioPago}
              setMedioPagoSeleccionado={setMedioPagoSeleccionado}
              mediosPago={mediosPago}
              dataEmpleado={dataEmpleado}
              errorEmpleado={errorEmpleado}
              isCuentaCorriente={isCuentaCorriente}
            />
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ReportTable
                    heightStyle={"calc(100vh - 475px)"}
                    billetera
                    items={formatDetalle(
                      detallesDisponibles,
                      handleChangeCantidad,
                      has_factura,
                    )}
                    columns={[
                      "Código",
                      "Detalle",
                      "Cantidad Real",
                      "Cantidad habilitada",
                      "Devolución",
                    ]}
                    loading={loading}
                  />
                </Grid>
                {hasMultipleMedioPago() && (
                  <Grid item xs={12}>
                    <AlertMultipleMedio
                      text={
                        isCuentaCorriente
                          ? "Esta venta posee al menos un pago en Cuenta Corriente por lo que el medio de pago no puede modificarse"
                          : "Esta venta posee múltiple medios de pagos, por lo que debera seleccionar un medio de pago."
                      }
                      severity={"info"}
                      hasMultipleMedioPago={hasMultipleMedioPago}
                    />
                  </Grid>
                )}

                {!isCuentaCorriente && !hasMultipleMedioPago() && (
                  <Box paddingTop={2}>
                    <AlertMultipleMedio
                      text={`Si no selecciona medio de pago, la ${
                        has_factura ? "nota de crédito" : "devolución"
                      } se generará por el mismo medio de la venta.`}
                      severity={"info"}
                      hasMultipleMedioPago={hasMultipleMedioPago}
                      porMontos={movimiento.tipoMov}
                      isCuentaCorriente={isCuentaCorriente}
                    />
                  </Box>
                )}

                <FieldsResponsableMedio
                  medioPagoSeleccionado={medioPagoSeleccionado}
                  errorMedioPago={errorMedioPago}
                  setMedioPagoSeleccionado={setMedioPagoSeleccionado}
                  mediosPago={mediosPago}
                  dataEmpleado={dataEmpleado}
                  errorEmpleado={errorEmpleado}
                  isCuentaCorriente={isCuentaCorriente}
                  hasMultipleMedioPago={hasMultipleMedioPago}
                  tipoMov={movimiento.tipoMov}
                  handleChangeResponsable={handleChangeResponsable}
                  selected={selected}
                />
                <Grid
                  item
                  // container
                  // justifyContent="flex-end"
                  xs={12}
                  md={4}
                  style={{ textAlign: "end" }}
                >
                  <Typography
                    style={{
                      fontWeight: "bolder",
                      textAlign: "end",
                      paddingBottom: 3,
                    }}
                    variant="subtitle2"
                  >
                    {`Monto habilitado: $${enabledAmount.toLocaleString(
                      "es-AR",
                    )}`}
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={montoTotalNC.toFixed(2)}
                    // inputProps={{ style: { textAlign: "center" } }}
                    type={"number"}
                    disabled={true}
                    label={"TOTAL"}
                    error={montoTotalNC > enabledAmount ? true : false}
                    helperText={
                      montoTotalNC > enabledAmount ? "Monto total excedido" : ""
                    }
                    className={montoTotalNC > enabledAmount ? classes.root : ""}
                    InputProps={{
                      style: {
                        textAlign: "center",
                      },
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ color: "red" }}
                        >
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Divider />

      <Grid
        container
        spacing={2}
        style={{ justifyContent: "end" }}
        className="pt-2"
      >
        <Grid item>
          <ButtonCancelar click={() => toggle()} message={"SALIR"} />
        </Grid>
        <Grid item>
          <GloboTooltip
            title={
              <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                {`Ya no puede generar una ${
                  has_factura ? "nota de crédito" : "devolución"
                } por artículos`}
              </h6>
            }
            id={"confirm-total"}
            placement="top"
            arrow
            open={openTooltip}
            onClose={handleClose}
            onOpen={handleOpen}
          >
            <span>
              <ButtonAceptar
                // disabled={
                //   movimiento.tipoMov
                //     ? montoTotalNC > enabledAmount || !enabledNC
                //       ? true
                //       : false
                //     : false
                // }
                disabled={
                  isCuentaCorriente &&
                  !hasMultipleMedioPago() &&
                  !selected.cliente.cuenta_corriente
                }
                click={validateResponsable}
                message={
                  has_factura
                    ? `${actionButton} nota de crédito`
                    : `${actionButton} devolución`
                }
              />
            </span>
          </GloboTooltip>
        </Grid>
      </Grid>
    </>
  );
}
