import { FACTURA_POR_DEFECTO } from "../../Clientes/utils";

export const getDataClienteInactivo = async (values, clienteInactivo) => {
  const dataCliente = {
    dni: values.numeroDocumento,
    domicilio: clienteInactivo.cliente.domicilio,
    razon_social: values.razonSocial,
    telefono: clienteInactivo.cliente.telefonoContacto
      ? clienteInactivo.cliente.telefonoContacto
      : null,
    correo: clienteInactivo.cliente.correoElectronico,
    estado_tributario: values.idEstadoTributario,
    provincia: clienteInactivo.cliente.provincia,
    cuit: clienteInactivo.cliente.CUIT,
    ingresos: null,
    deuda_maxima: clienteInactivo.deudaMaxima,
    is_meli: clienteInactivo.cliente_meli,
    es_comision: clienteInactivo.es_comision,
    comision: clienteInactivo.comision,
    id_descuento: clienteInactivo.descuento_prefijado
      ? clienteInactivo.descuento_prefijado.id_descuento
      : clienteInactivo.descuento_prefijado,
    recargo: clienteInactivo.recargo,
    cuenta_corriente: clienteInactivo.cuenta_corriente,
    responsable_cuenta: clienteInactivo.idCliente.responsable_cuenta,
    esta_grabado_iibb: clienteInactivo.idCliente.esta_grabado_iibb,
    dias_deuda: clienteInactivo.dias_deuda,
    observacion: clienteInactivo.observacion,
    is_cliente_inactivo: true,
    comprobante_por_defecto: "AMBOS",
  };

  return dataCliente;
};

export const getDataClienteCreate = async (values, provinciaDefault) => {
  const dataClienteCreate = {
    dni: values.numeroDocumento,
    domicilio: "",
    razon_social: values.razonSocial,
    telefono: "",
    correo: "",
    estado_tributario: values.idEstadoTributario,
    provincia: provinciaDefault ? provinciaDefault.id : null,
    cuit: "",
    ingresos: null,
    deuda_maxima: 0,
    is_meli: false,
    es_comision: false,
    comision: 0,
    id_descuento: null,
    recargo: [],
    observacion: [],
    cuenta_corriente: 0,
    responsable_cuenta: "",
    esta_grabado_iibb: false,
    dias_deuda: null,
    comprobante_por_defecto: "AMBOS",
  };

  return dataClienteCreate;
};
