import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import Line_A4, { Line_A4_table, Line_A4_table_white } from "../raya";

export default function BodyTableSinPrecio({ descripcion, cant, noLine }) {
  return (
    <View style={{ paddingTop: 5 }}>
      <View style={styles.containerRowBodyTable}>
        <View style={(styles.containerColumn, { width: 450 })}>
          <Text style={styles.textLeftTable}>
            {`${
              descripcion.substr(0, 150).trim() +
              (descripcion.length > 150 ? "..." : "")
            }` || "---"}
          </Text>
        </View>
        <View style={(styles.containerColumn, { width: 65 })}>
          <Text style={styles.textCenterTable}>{cant}</Text>
        </View>
      </View>
      <View style={{ marginTop: -15 }}>
        {noLine ? <Line_A4_table_white /> : <Line_A4_table />}
      </View>
    </View>
  );
}
