import React from "react";
import { Button, CircularProgress } from "@material-ui/core";

export default function ButtonAceptar({
  disabled,
  click,
  message,
  fullwidth,
  loading = false,
}) {
  return (
    <Button
      style={{ outline: "none", backgroundColor: "#17c671", color: "#fff" }}
      fullWidth={fullwidth || false}
      variant="contained"
      type="submit"
      onClick={click}
      disabled={disabled}
    >
      {loading && (
        <CircularProgress size={20} style={{ marginRight: "10px" }} />
      )}
      {message}
    </Button>
  );
}
