import * as Yup from "yup";

export const validationYup = Yup.object({
  idEstadoTributario: Yup.number()
    .nullable()
    .required("Seleccione un estado tributario."),
  provincia: Yup.number().nullable().required("Seleccione una provincia."),
  CUIT: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(9999999999, "El CUIT ingresado no es válido.")
    .max(99999999999, "El CUIT ingresado no es válido.")

    .when("idEstadoTributario", {
      is: (value) => value && (value === 1 || value === 2 || value === 3),
      then: Yup.number().required("Ingrese un nro. CUIT válido."),
    }),
  numeroDocumento: Yup.number()
    .min(0, "El nro de documento no es válido.")
    .max(99999999, "El nro de documento no es válido.")
    .when("idEstadoTributario", {
      is: (value) => value && value === 5,
      then: Yup.number().required("Ingrese un nro. de documento válido."),
    })
    .nullable(),
  razonSocial: Yup.string().required("Ingrese nombre o razón social."),
  correoElectronico: Yup.string()
    .nullable()
    .when("envio_automatico_comprobante_venta", {
      is: true,
      then: Yup.string().required(
        "El correo electrónico es obligatorio para el envio de comprobantes.",
      ),
      otherwise: Yup.string().nullable(),
    }),
});

export const validationYupAutomatico = (isCuit) =>
  Yup.object({
    idEstadoTributario: Yup.number()
      .nullable()
      .required("Seleccione un estado tributario."),
    CUIT: Yup.number("Ingrese un número de CUIT válido.")
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(9999999999, "El CUIT ingresado no es válido.")
      .max(99999999999, "El CUIT ingresado no es válido.")
      .when("idEstadoTributario", {
        is: (value) =>
          value && isCuit && (value === 1 || value === 2 || value === 3),
        then: Yup.number().required("Ingrese un nro. CUIT válido."),
      }),

    dni: Yup.number("Ingrese un número de DNI válido.")
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(0, "El DNI ingresado no es válido.")
      .max(999999999, "El DNI ingresado no es válido.")
      .nullable()
      .when("idEstadoTributario", {
        is: (value) =>
          value && !isCuit && (value === 1 || value === 2 || value === 3),
        then: Yup.number().required("Ingrese un nro. DNI válido."),
      }),
    // .transform((_, val) => (val === Number(val) ? val : null)),
    correoElectronico: Yup.string()
      .nullable()
      .when("envio_automatico_comprobante_venta", {
        is: true,
        then: Yup.string().required(
          "El correo electrónico es obligatorio para el envio de comprobantes.",
        ),
        otherwise: Yup.string().nullable(),
      }),
  });

export const initialState = ({
  idEstadoTributario,
  provincia,
  comprobantePorDefecto,
  impuestos_boxer,
}) => {
  return {
    CUIT: "",
    correoElectronico: "",
    domicilio: "",
    idEstadoTributario,
    provincia,
    numeroDocumento: "",
    razonSocial: "",
    telefonoContacto: "",
    cliente_meli: false,
    deudaMaxima: "",
    es_comision: false,
    comision: "0",
    cuenta_corriente: false,
    dias_deuda: "",
    dni: "",
    comprobantePorDefecto,
    envio_automatico_comprobante_venta: false,
    impuestos: impuestos_boxer.map((imp) => ({
      //Se carga el IIBB defecto seleccionado
      ...imp,
      activo:
        imp.tipo_actualizacion === "Manual" &&
        imp.nombre === "Percepción de IIBB",
    })),
  };
};

export const initialStateAutomatico = ({
  datosOrdenMeli,
  impuestos_boxer = [],
}) => ({
  CUIT:
    (datosOrdenMeli &&
      datosOrdenMeli.length &&
      datosOrdenMeli[0].buyer_doc_number &&
      String(datosOrdenMeli[0].buyer_doc_number)) ||
    "",
  dni: "",
  idEstadoTributario: 1,
  cliente_meli: false,
  cuenta_corriente: false,
  esta_grabado_iibb: false,
  deudaMaxima: "",
  dias_deuda: "",
  telefonoContacto: "",
  correoElectronico: "",
  tipo_cliente: null,
  comprobantePorDefecto: "AMBOS",
  envio_automatico_comprobante_venta: false,
  impuestos: impuestos_boxer.map((impuesto) => ({
    //Se carga el IIBB defecto seleccionado
    activo:
      impuesto.tipo_actualizacion === "Manual" &&
      impuesto.nombre === "Percepción de IIBB",
    id: impuesto.id,
    nombre: impuesto.nombre,
    alicuota: impuesto.alicuota,
    tipo_actualizacion: impuesto.tipo_actualizacion,
    fecha_vencimiento: null,
    fecha_inicio: null,
  })),
});
